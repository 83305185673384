// @flow

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import isNumber from 'lodash/isNumber';

import type { MessageTranslator } from '@oneflowab/pomes';
import { localize, Message } from '@oneflowab/pomes';
import { getCurrentLanguageSelector } from 'reducers/i18n';
import { getAccountFromSessionSelector } from 'reducers/session';
import { isPending } from 'agreement';
import { isSignOrderEnabled, isAgreementOwner, getAgreementMyParty } from 'agreement/selectors';
import { shouldDisableField } from 'agreement/agreement-participants';
import {
  shouldValidatePersonalId,
  getSignMethodPlaceholder,
} from 'agreement/participant';
import isOrganizer from 'agreement/participant/is-organizer';

import { ExpandableOptionsHeader } from 'components/expandable-options-header';
import Field from 'components/field';
import {
  EmailField,
  CountryField,
  ParticipantRoleField,
  PhoneNumberField,
} from 'components/fields';
import InfoIcon from 'components/icons/info';
import TextField from 'components/text-field';

import {
  ssnValidator,
  participantPhoneNumber,
} from 'forms/validators';

import style from './participant-section.module.scss';

type Props = {
  message: MessageTranslator,
  individual?: boolean,
  isColleague?: boolean,
  participant?: AgreementParticipant,
  validateUniqueEmail?: () => Promise<any> | null,
  validateUniquePhone?: () => Promise<any> | null,
  hasSaveToAddressBook?: boolean,
  onRoleChanged?: number => void,
  values?: {
    jobTitle?: string,
    fullname?: string,
    email?: string,
    phoneNumber?: string,
    personalIdentification?: string,
    role?: number,
    country?: Option,
    countryData?: { countryCode: string, country: string }
  },
  isPhoneNumberRequired?: boolean,
  isEmailRequired?: boolean,
  hiddenRoles?: Array<any>,
  countryForPhone?: string,
  participantAcl?: Acl,
  onCountryChanged?: Option => void,
  currentSignMethod?: any,
  isAgreementPublished?: boolean,
  agreement: Agreement,
  setCountryCode?: () => void,
  setCountry?: () => void,
}

export const ParticipantSection = ({
  message,
  individual,
  isColleague,
  participant,
  validateUniqueEmail,
  validateUniquePhone,
  hasSaveToAddressBook,
  onRoleChanged,
  values,
  isPhoneNumberRequired,
  isEmailRequired,
  hiddenRoles,
  countryForPhone,
  participantAcl,
  onCountryChanged,
  currentSignMethod,
  isAgreementPublished,
  agreement,
  setCountryCode,
  setCountry,
}: Props) => {
  const account = useSelector(getAccountFromSessionSelector);
  const isOwner = isAgreementOwner(account, agreement);
  const [inputFieldCurrentValue, setInputFieldCurrentValue] = useState();
  const [initialValues] = useState(values);
  const myParty = getAgreementMyParty(agreement);
  const isPendingAgreement = isPending(agreement);
  const hasSignOrder = isSignOrderEnabled(agreement);
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleArea = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
  };
  const lang = useSelector((state) => (
    getCurrentLanguageSelector(state)
  ));

  const getDefaultCountry = () => (
    values?.country?.value?.toLowerCase() || countryForPhone || undefined
  );

  const renderCountryOrJobTitleField = () => {
    if (individual) {
      return (
        <CountryField
          languageCode={lang}
          initialOption={values?.country}
          disabled={shouldDisableField(participantAcl, 'participant:update:country')}
          onChange={onCountryChanged}
          required
        />
      );
    }

    return (
      <Field
        name="jobTitle"
        label={message({
          id: 'Job title',
          comment: 'Field label in add/edit counterparty modal.',
        })}
        placeholder={message({
          id: 'Enter job title',
          comment: 'Field placeholder in add/edit counterparty modal.',
        })}
        component={TextField}
        initialValue={values?.jobTitle}
        disabled={shouldDisableField(participantAcl, 'participant:update:title')}
        validateFields={[]}
      />
    );
  };

  const renderPersonalIdentificationInfo = () => (
    <p>
      <Message
        id="Personal ID number or equivalent that identifies the participant."
        comment="Tooltip help text explaining the personal identification field."
      />
    </p>
  );

  const areaClassNames = clsx(style.ParticipantArea, {
    [style.Hidden]: !isExpanded,
    [style.ParticipantAreaWithSaveToAddressBook]: hasSaveToAddressBook,
  });

  const validatePersonalIdentification = (value, allValues) => {
    const signMethod = isNumber(allValues.signMethod)
      ? allValues.signMethod : allValues.signMethod?.value;
    if (!shouldValidatePersonalId(signMethod) || !value?.length) {
      return null;
    }
    return ssnValidator({ message, signMethod })(value);
  };

  const validatePhoneNumber = (value, allValues) => participantPhoneNumber({ message })({
    value,
    participantCountry: allValues.country,
    selectedCountry: allValues.countryData,
    inputFieldCurrentValue,
  });

  const getPersonalIdentificationPlaceholder = () => {
    const signMethod = isNumber(currentSignMethod)
      ? currentSignMethod : currentSignMethod?.value;
    if (shouldValidatePersonalId(signMethod)) {
      return getSignMethodPlaceholder(message, signMethod);
    }

    return (message({
      id: 'Enter personal identification number',
      comment: 'Field placeholder in add/edit counterparty modal.',
    }));
  };

  const renderPersonalIdentification = () => {
    if (isOwner && isColleague) {
      return null;
    }

    return (
      <Field
        name="personalIdentification"
        label={message({
          id: 'Personal identification',
          comment: 'Field label in add/edit counterparty modal.',
        })}
        placeholder={getPersonalIdentificationPlaceholder()}
        component={TextField}
        fieldinfo={{
          message: renderPersonalIdentificationInfo(),
          icon: <InfoIcon width="12px" />,
          side: 'top',
          zIndex: '10004',
          nextToLabel: true,
        }}
        initialValue={values?.personalIdentification}
        disabled={shouldDisableField(participantAcl, 'participant:update:ssn')}
        validateFields={[]}
        validate={validatePersonalIdentification}
      />
    );
  };

  const shouldDisableEmailField = () => (
    (isOwner && values?.email && isColleague)
    || shouldDisableField(participantAcl, 'participant:update:email')
  );

  const shouldDisableRoleField = () => (
    shouldDisableField(participantAcl, 'participant:update:type')
    || (isAgreementPublished && isOrganizer({ ...values, type: values?.role }))
    || (hasSignOrder && isPendingAgreement && !myParty)
  );

  return (
    <div className={style.ExpandableArea}>
      <ExpandableOptionsHeader
        title={message({
          id: 'Participant',
          comment: 'Used as the header of the section in the counterparty modal.',
        })}
        onClick={toggleArea}
        isExpanded={isExpanded}
        customClass={style.SectionHeader}
      />
      <div className={areaClassNames}>
        <div className={style.Row}>
          <Field
            name="fullname"
            label={message({
              id: 'Full name',
              comment: 'Field label in add/edit counterparty modal.',
            })}
            placeholder={message({
              id: 'Enter full name',
              comment: 'Field placeholder in add/edit counterparty modal.',
            })}
            component={TextField}
            required
            initialValue={values?.fullname}
            disabled={shouldDisableField(participantAcl, 'participant:update:fullname')}
            validateFields={[]}
          />
          <EmailField
            key={isEmailRequired}
            required={isEmailRequired}
            unique={{
              text: message({
                id: 'A person with that email address already exists in the contract.',
                comment: 'Form validation error message.',
              }),
              param: 'email',
              request: validateUniqueEmail,
              initialValue: initialValues?.email,
            }}
            initialValue={values?.email}
            disabled={shouldDisableEmailField()}
            displayErrorEarly={initialValues?.email !== values?.email}
            fieldinfo={shouldDisableEmailField() ? {
              message: message({
                id: "This email can be changed in the admin pages (Users) or on the user's profile page.",
                comment: 'Tooltip message for the email field for colleagues.',
              }),
              icon: <InfoIcon width="12px" />,
              side: 'top',
              zIndex: '10004',
              nextToLabel: true,
            } : undefined}
          />
        </div>
        <div className={style.Row}>
          {renderCountryOrJobTitleField()}
          <PhoneNumberField
            defaultCountry={getDefaultCountry()}
            unique={{
              text: message({
                id: 'A person with that phone number already exists in the contract.',
                comment: 'Form validation error message.',
              }),
              param: 'phoneNumber',
              request: validateUniquePhone,
              initialValue: initialValues?.phoneNumber,
            }}
            initialValue={values?.phoneNumber}
            required={isPhoneNumberRequired}
            disabled={shouldDisableField(participantAcl, 'participant:update:phone_number')}
            validate={validatePhoneNumber}
            validateFields={[]}
            displayErrorEarly
            setCountryCode={setCountryCode}
            setCountry={setCountry}
            onCountryChangeState={countryForPhone}
            setInputFieldCurrentValue={setInputFieldCurrentValue}
          />
        </div>
        <div className={style.Row}>
          {renderPersonalIdentification()}
          <ParticipantRoleField
            hiddenRoles={hiddenRoles}
            onChange={(option) => onRoleChanged(option?.value)}
            initialValue={values?.role}
            disabled={shouldDisableRoleField()}
            hasSignOrder={hasSignOrder}
            isColleague={isColleague}
            participant={participant}
            agreement={agreement}
          />
        </div>
        <Field name="countryData" component="input" type="hidden" initialValue={values?.countryData} />
      </div>
    </div>
  );
};

export default localize<Props>(ParticipantSection);
