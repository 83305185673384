import Svg from 'components/svg';

const Fr = Svg((
  <>
    <path d="M0 0h170.667v336H0z" fill="#0052B4" />
    <path d="M170.667 0h170.666v336H170.667z" fill="#F5F5F5" />
    <path d="M341.333 0H512v336H341.333z" fill="#D80027" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Fr.displayName = 'Fr';

export default Fr;
