import clsx from 'clsx';

import AiSparkleIcon from 'components/icons/ai-sparkle';
import useAgreement from 'hooks/use-agreement';

import style from './import-sparkle.module.scss';

type Props = {
  agreementId: number;
  type: 'boxes' | 'parties' | 'participants' | 'closeTime';
  matchId?: number;
  className?: string;
};

export const ImportSparkle = ({
  agreementId,
  type,
  matchId,
  className,
}: Props) => {
  const { aiImportData } = useAgreement(agreementId);
  if (!aiImportData) {
    return null;
  }
  const isAiExtracted = () => {
    if (!matchId) {
      return Boolean(aiImportData?.[type]);
    }

    return Boolean(aiImportData
      && Array.isArray(aiImportData[type])
      && aiImportData[type].find((item) => item.id === matchId));
  };

  if (isAiExtracted()) {
    return (
      <div className={clsx(style.ImportSparkle, className)}>
        <AiSparkleIcon className={style.SparkleIcon} height="12px" />
        <small>AI Extract</small>
      </div>
    );
  }

  return null;
};
