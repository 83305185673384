import { useEffect } from 'react';
import webSocket from 'web-socket';
import { useDispatch, useSelector } from 'react-redux';
import { partial } from 'lodash';

import { discardChanges, isContractPristine } from 'reducers/current-contract';
import agreementsReducer from 'reducers/entities/agreements';
import { isConcluded, isPending, isStateDraft } from 'agreement/states';
import useAgreement from 'hooks/use-agreement';

import { renderPartiallySignedToast } from '../partially-signed-toast';

type Event =
  'agreement:participant:sign' |
  'agreement:participant:approve' |
  'agreement:participant:decline' |
  'agreement:participant:create' |
  'agreement:participant:update' |
  'agreement:party:update';

// Following events are not the type of events that should replace the whole agreement in redux.
// They only update the participant or participants' fields.
export const getPusherEvents = (state: number): { event: Event, condition: boolean }[] => [
  {
    event: 'agreement:participant:sign',
    condition: isPending({ state }),
  },
  {
    event: 'agreement:participant:approve',
    condition: isPending({ state }) || isStateDraft(state),
  },
  {
    event: 'agreement:participant:decline',
    condition: isPending({ state }),
  },
  {
    event: 'agreement:participant:create',
    condition: true,
  },
  {
    event: 'agreement:participant:update',
    condition: !isConcluded({ state }),
  },
  {
    event: 'agreement:party:update',
    condition: !isConcluded({ state }),
  },
];

type SubscribeToEventsParams = {
  agreementId: Oneflow.Document['id'];
  agreementState: Oneflow.Document['state'];
  fetchAgreement: (event: Event) => void;
}

const subscribeToEvents = ({
  agreementId,
  agreementState,
  fetchAgreement,
}: SubscribeToEventsParams) => {
  const subscriptions: (() => void)[] = [];
  if (typeof agreementState !== 'number') return subscriptions;

  const events = getPusherEvents(agreementState);
  events.forEach(({ event, condition }) => {
    if (condition) {
      const unsubscribe = webSocket.subscribe({
        channelName: `private-agreement-${agreementId}`,
        event,
        eventCallback: partial(fetchAgreement, event),
      });
      if (unsubscribe) {
        subscriptions.push(unsubscribe);
      }
    }
  });

  return subscriptions;
};

type OnSuccessArgs = {
  normalized: {
    count: number,
    entities: {
      agreements: Record<Oneflow.Agreement['id'], Oneflow.Agreement>
    },
    result: number
  },
}

const useFetchAgreementParticipants = (
  agreementId: number,
  guestToken?: string,
) => {
  const dispatch = useDispatch();
  const isPristine = useSelector(isContractPristine);
  const agreement = useAgreement(agreementId);

  useEffect(() => {
    const subscriptions = subscribeToEvents({
      agreementId,
      agreementState: agreement.state,
      fetchAgreement: (event: Event) => {
        dispatch(agreementsReducer.fetchParticipantsSignState(({
          id: agreementId,
          data: { guestToken },
          pipe: {
            onSuccess: ({ normalized }: OnSuccessArgs) => {
              const _agreement = normalized.entities.agreements[agreementId];
              if (event === 'agreement:participant:sign') {
                renderPartiallySignedToast(_agreement);
                if (isPristine) {
                  dispatch(discardChanges(_agreement));
                }
              }
            },
          },
        })));
      },
    });

    return () => {
      subscriptions?.forEach((unsubscribe) => unsubscribe?.());
    };
  }, [
    dispatch,
    agreementId,
    guestToken,
    agreement.state,
    isPristine,
  ]);
};

export default useFetchAgreementParticipants;
