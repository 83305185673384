import { Link } from 'react-router-dom';
import type { MouseEventHandler } from 'react';
import { Message } from '@oneflowab/pomes';

import {
  getAgreementCounterparties,
  getAgreementMyParty,
} from 'agreement/selectors';
import { isDraft } from 'agreement';

import Tooltip from 'components/tooltip';

import { ContractName } from './contract-name';

import style from './contract-name.module.scss';

type Props = {
  agreement: Oneflow.Agreement;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: string;
  className?: string;
  disableTooltip?: boolean;
};

const ContractNameLink = ({
  agreement,
  onClick,
  target,
  className,
  disableTooltip = false,
}: Props) => {
  const href = `/documents/${agreement.id}`;

  const counterparties = getAgreementCounterparties(agreement);
  const joinedCounterpartyNames = counterparties.map((party) => party.name).join(', ');
  const myParty = getAgreementMyParty(agreement) || {};
  const isDraftDocumentWithNoCounterparty = isDraft(agreement) && counterparties.length === 0;

  const getDocumentNameWithoutCounterparties = () => {
    if (isDraftDocumentWithNoCounterparty) {
      return (
        <Message
          id="Untitled"
          comment="Shown in document list for drafts without counterparties."
        />
      );
    }

    return myParty.name;
  };

  return (
    <Tooltip
      contentClassName={style.TooltipContent}
      message={
        agreement.name
        || joinedCounterpartyNames
        || getDocumentNameWithoutCounterparties()
      }
      messageClassName={style.TooltipMessage}
      side="top"
      align="start"
      zIndex="10002"
      hideContent={disableTooltip}
    >
      <Link
        to={href}
        onClick={onClick}
        target={target}
        rel="noopener noreferrer"
        className={className}
      >
        <ContractName agreement={agreement} />
      </Link>
    </Tooltip>
  );
};

export default ContractNameLink;
