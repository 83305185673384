import { useState, useEffect, useCallback } from 'react';
import { merge, map } from 'lodash';
import type { match as Match } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import rolesReducer from 'reducers/entities/roles';
import usePrevious from 'hooks/use-previous';
import { replace } from 'connected-react-router';

import adminPage from 'hocs/admin-page';
import Conditional from 'components/conditional';
import { checkAcl } from 'components/acl';
import { isRoleEditable } from 'role';
// eslint-disable-next-line import/named
import { PermissionsComponent } from './permissions-component';
import ActionBar from './action-bar';

import style from './permissions.module.scss';

type Props = {
  location: Location,
  match: Match,
};

type PermissionChange = {
  id: number,
  enabled: boolean,
};

const QUERY_NAME = 'admin/roles';

export const PermissionsWrapperComponent = ({
  location,
  match,
}: Props) => {
  const dispatch = useDispatch();
  const roleId = parseInt(match.params.id, 10);
  const updateState = useSelector((state) => rolesReducer.getUpdateSelector(state, { id: roleId }));
  const rolesQuery = useSelector((
    state,
  ) => rolesReducer.getQuerySelector(state, { name: QUERY_NAME }));
  const roles = useSelector(
    (state) => rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
  );
  const role = roles.find((x) => x.id === roleId);

  const [editingEnabled, setEditingEnabled] = useState(false);
  const [changedPermissions, setChangedPermissions] = useState<Record<string, boolean>>({});

  const checkEditPermission = useCallback(() => checkAcl(role?.acl, 'role:update:permissions'), [role]);

  useEffect(() => {
    if (!editingEnabled && location?.query?.edit && checkEditPermission()) {
      dispatch(replace(location.pathname));
      setEditingEnabled(true);
    }
  }, [location, editingEnabled, updateState, checkEditPermission, dispatch]);

  const cancel = () => {
    setEditingEnabled(false);
    setChangedPermissions({});
  };

  const handlePermissionChange = ({ id, enabled }: PermissionChange) => {
    if (!editingEnabled) {
      return;
    }

    setChangedPermissions((prev) => merge({}, prev, { [id]: enabled }));
  };

  const updateRole = (roleToUpdate: Oneflow.Role) => {
    const permissions = map(changedPermissions, (enabled, id: string) => ({
      id: parseInt(id, 10),
      enabled,
    }));

    dispatch(rolesReducer.updateRole({
      id: roleToUpdate.id,
      data: {
        ...roleToUpdate,
        permissions,
      },
      pipe: {
        onSuccess: () => {
          setEditingEnabled(false);
        },
      },
    }));
  };

  const isEditingEnabled = () => editingEnabled && !updateState?.loading;

  const renderRolePage = () => {
    if (!role) {
      return null;
    }

    const noPermissionChanged = Object.keys(changedPermissions).length === 0;

    return (
      <>
        <Conditional ifCondition={isRoleEditable(role)}>
          <ActionBar
            role={role}
            canEdit={checkEditPermission()}
            isEditingEnabled={editingEnabled}
            isConfirmDisabled={editingEnabled && noPermissionChanged}
            onClick={() => setEditingEnabled(true)}
            onConfirm={() => updateRole(role)}
            onCancel={cancel}
            onSuccess={cancel}
            updateState={updateState}
          />
        </Conditional>
        <PermissionsComponent
          role={role}
          changedPermissions={changedPermissions}
          editingEnabled={isEditingEnabled()}
          handlePermissionChange={handlePermissionChange}
        />
      </>
    );
  };

  return (
    <div className={style.RolePage}>
      {renderRolePage()}
    </div>
  );
};

export default adminPage(({ props: { message } }) => ({
  title: message({ id: 'Permissions', comment: 'Used in the breadcrumb and title on the role page.' }),
  showAsLink: false,
  modules: [[]],
}))(PermissionsWrapperComponent);
