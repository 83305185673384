import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { checkAcl } from 'components/acl';
import { getCurrentWorkspaceSelector } from 'reducers/app';
import { BOX_DURATION } from 'agreement/constants';

import { ImportSparkle } from 'components/import-sparkle/import-sparkle';
import useAgreement from 'hooks/use-agreement';

import style from './import-wrapper.module.scss';

type Props = {
  agreementId: number;
  boxId: number;
  boxType: number;
  children: ReactNode;
};

export const ImportWrapper = ({
  agreementId, boxId, boxType, children,
}: Props) => {
  const agreement = useAgreement(agreementId);
  const currentWorkspace = useSelector(getCurrentWorkspaceSelector);
  const isAiImportOptionEnabled = checkAcl(currentWorkspace.acl, 'collection:ai_import:use');
  const { aiImportData } = agreement;
  const boxes = aiImportData?.boxes;
  const isAiExtracted = Boolean(boxes?.find((item) => item.id === boxId));

  if (boxType !== BOX_DURATION || !isAiImportOptionEnabled || !isAiExtracted) {
    return children;
  }

  return (
    <div className={style.ImportWrapper}>
      <div className={style.ImportSparkleContainer} data-testid="import-sparkle-container">
        <ImportSparkle agreementId={agreementId} type="boxes" matchId={boxId} />
      </div>
      {children}
    </div>
  );
};
