import { ReactNode, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGuestToken } from 'agreement/selectors';
import { isPending, isDraft } from 'agreement/states';
import { MESSAGE_TYPES, COMMENT_STATES } from 'comments/constants';
import { MESSAGES_TAB } from 'agreement/constants';
import { useAnnotationProps } from 'contexts/annotation-props';

import {
  setAgreementSidebarActiveTabName,
  setAgreementSidebarCommentState,
  setAgreementSidebarMessageType,
} from 'reducers/app';
import useAgreement from 'hooks/use-agreement';
import useCurrentMessages from 'hooks/use-current-messages';

import AlertIcon from 'components/icons/alert';
import Button from 'components/button';
import { CancelButton } from 'components/buttons';
import Confirmable from 'components/confirmable';
import Message from 'components/message';

import style from './resolve-annotations.module.scss';

const BodyContent = ({ getActionMessage }: { getActionMessage: () => ReactNode }) => (
  <div className={style.Body}>
    <div className={style.Icon}><AlertIcon /></div>
    {getActionMessage()}
  </div>
);

type Props = {
  children: ReactNode | ((openModal: () => void) => ReactNode),
  agreementId: number,
  openCommentsTabCb?: () => void,
}

const ResolveAnnotations = ({ children, agreementId, openCommentsTabCb }: Props) => {
  const {
    activeComments,
    activeSuggestions,
  } = useCurrentMessages();
  const { setHighlightedAnnotationId } = useAnnotationProps();
  const dispatch = useDispatch();
  const isGuest = Boolean(useSelector(getGuestToken));
  const agreement = useAgreement(agreementId);

  const annotationsNumbers = useMemo(() => {
    if (isDraft({ state: agreement.state })) {
      return {
        activeCommentsLength: activeComments.length,
        activeSuggestionsLength: activeSuggestions.length,
      };
    }
    if (isPending({ state: agreement.state })) {
      return {
        activeSuggestionsLength: activeSuggestions.length,
      };
    }
    return {};
  }, [agreement.state, activeComments.length, activeSuggestions.length]);

  const showComments = useCallback(() => {
    dispatch(setAgreementSidebarActiveTabName(MESSAGES_TAB));
    dispatch(setAgreementSidebarMessageType(MESSAGE_TYPES.COMMENTS));
    dispatch(setAgreementSidebarCommentState(COMMENT_STATES.COMMENT_ACTIVE));

    if (isDraft({ state: agreement.state })) {
      const allAnnotationsInDocument = document.getElementsByClassName('annotation');
      setHighlightedAnnotationId(Number(allAnnotationsInDocument[0].id.split('-')[1]));
    } else {
      const suggestionsInDocument = document.querySelectorAll('span[class*="_SuggestionAnnotation"]');
      setHighlightedAnnotationId(Number(suggestionsInDocument[0].id.split('-')[1]));
    }

    if (typeof openCommentsTabCb !== 'undefined') {
      openCommentsTabCb();
    }
    // TODO: Refactor to use React Router Redirect API when migrated to react-router v6
  }, [dispatch, openCommentsTabCb, setHighlightedAnnotationId, agreement.state]);

  const renderActiveComments = () => {
    if (
      !annotationsNumbers.activeCommentsLength
      || isPending({ state: agreement.state })
    ) {
      return null;
    }
    return (
      <li>
        <strong>
          <Message
            id="{number} active comment"
            pluralId="{number} active comments"
            pluralCondition="number"
            values={{
              number: activeComments.length,
            }}
            comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
          />
        </strong>
      </li>
    );
  };

  const renderActiveSuggestions = () => {
    if (!annotationsNumbers.activeSuggestionsLength) {
      return null;
    }

    return (
      <li>
        <strong>
          <Message
            id="{number} active suggestion"
            pluralId="{number} active suggestions"
            pluralCondition="number"
            values={{
              number: activeSuggestions.length,
            }}
            comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
          />
        </strong>
      </li>
    );
  };

  const renderActionText = () => {
    if (isPending({ state: agreement.state }) && isGuest) {
      return (
        <Message
          id="Document sender needs to review and resolve:"
          comment="Text to show that the document owner needs to resolve suggestions for the agreement to be ready for signing"
        />
      );
    }

    return (
      <Message
        id="To proceed, please review and resolve:"
        comment="Text to show that the user needs to resolve suggestions to be able to send the agreement"
      />
    );
  };

  const renderActionTextFooter = () => {
    if (isPending({ state: agreement.state }) && isGuest) {
      <Message
        id="Once resolved, you'll be able to sign."
        comment="Text to show that the document owner needs to resolve suggestions for the agreement to be ready for signing"
      />;
    }

    return null;
  };

  const getActionMessage = () => (
    <div style={{ width: 400 }}>
      {renderActionText()}
      {renderActiveComments()}
      {renderActiveSuggestions()}
      {renderActionTextFooter()}
    </div>
  );

  const getActions = useCallback(({ closeConfirmation }) => (
    <div className={style.ActionsWrapper}>
      <CancelButton
        onClick={closeConfirmation}
      />
      <Button
        onClick={() => {
          closeConfirmation();
          showComments();
        }}
        kind="primary"
      >
        <Message
          id="Show pending"
          comment="Text for button show active suggestions."
        />
      </Button>
    </div>
  ), [showComments]);

  return (
    <Confirmable
      header={(
        <Message
          id="Pending review"
          comment="Text to show in the header of a modal when there are unresolved comments or text change suggestions."
        />
      )}
      body={<BodyContent getActionMessage={getActionMessage} />}
      modalKey="Resolve comments modal"
      actions={getActions}
    >
      {children}
    </Confirmable>
  );
};

export default ResolveAnnotations;
