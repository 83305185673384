// @flow

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { localize, Message } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { getAccountFromSessionSelector } from 'reducers/session';

import {
  getParticipantSignOrderBlockIndex,
  shouldShowRoleEditWarningModal,
  shouldShowRemoveSignatoryWarning,
  hasPendingStateApprovers,
  hasAnyPendingApproverApproved,
} from 'agreement/pending-state-flow';

import {
  isFormerColleague,
  isPhoneNumberRequired,
  isEmailRequired,
} from 'agreement/participant';

import {
  TYPE_IS_SIGNATORY,
} from 'agreement/participant/constants';
import {
  isPublished,
  hasDraftApprovalFlow,
  isConcluded,
} from 'agreement';
import { isImportInProgress } from 'agreement/import';
import { hasParticipantEmail, hasParticipantPhone } from 'agreement/agreement-participants';
import {
  getHiddenRoles,
} from 'agreement/agreement';
import { getNewInternalApproverBlockIndex } from 'agreement/draft-approval-flow';
import {
  getGuestToken,
  isAgreementOwner,
  isPartiallySigned,
  getParticipantById,
} from 'agreement/selectors';
import ModalForm from 'hocs/modal-form';

import { checkAcl } from 'components/acl';
import { InfoBox } from 'components/error-box';
import Button from 'components/button';
import ParticipantSection from 'components/participant-section';
import { RemovingParticipantFromDocument } from 'components/modals/removing-participant-from-document';
import SigningDetails from 'components/signing-details';
import RemoveParticipant from 'components/remove-participant';
import { CancelButton } from 'components/buttons/cancel';
import { ConfirmButton } from 'components/buttons/confirm';
import { ChangingRoleWarningModal } from 'components/modals/changing-role-warning';
import Delete from 'components/icons/delete';
import toast from 'components/toasts';

import { willTriggerSignatureReset } from './utils';
import style from './edit-participant.module.scss';

type Props = {
  agreement: Agreement,
  children: React.Node,
  participant?: Participant,
  isIndividual: boolean,
  isColleague: boolean,
  party?: Party,
  handleSignatureResetOnEdit: (Function, string) => void,
  handleApprovalsResetOnEdit: (Function, string) => void,
  handleApprovalsSignaturesResetOnEdit: (Function, string) => void,
};

export const EditParticipantComponent = ({
  agreement,
  children,
  participant,
  isIndividual,
  isColleague,
  party,
  handleSignatureResetOnEdit,
  handleApprovalsResetOnEdit,
  handleApprovalsSignaturesResetOnEdit,

}: Props) => {
  const [countryCode, setCountryCode] = useState('');
  const [country, setCountry] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isRemoveParticipantModalOpen, setIsRemoveParticipantModalOpen] = useState(false);
  const [isChangingRoleWarningModalOpen, setIsChangingRoleWarningModalOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);

  const isSignOrderEnabled = agreement.config?.signOrder;

  const defaultParticipantValues = {
    fullname: participant.fullname,
    email: participant.email,
    phoneNumber: participant.phoneNumber,
    personalIdentification: participant.ssn,
    role: participant.type,
    country: participant.country,
    jobTitle: participant.title,
  };

  const [currentParticipantValues, setCurrentParticipantValues] = useState(
    defaultParticipantValues,
  );

  const defaultSigningDetailsValues = {
    deliveryChannel: participant.deliveryChannel,
    signMethod: participant.signMethod,
    twoStepAuthenticationMethod: participant.mfaChannel,
  };

  const [currentSigningDetailsValues, setCurrentSigningDetailsValues] = useState(
    defaultSigningDetailsValues,
  );

  const [
    removeParticipantConfirmation,
    setRemoveParticipantConfirmation,
  ] = useState(false);

  const accountFromSession = useSelector((state) => (
    getAccountFromSessionSelector(state)
  ));
  const isOwner = isAgreementOwner(accountFromSession, agreement);

  const rpcState = useSelector((state) => (
    agreementsReducer.getUpdateParticipantSelector(state, { id: participant.id })
  ));

  const guestToken = useSelector((state) => getGuestToken(state));
  const isGuest = Boolean(guestToken);

  const dispatch = useDispatch();

  const resetRpcState = () => dispatch(agreementsReducer.updateParticipantReset({
    id: participant.id,
  }));

  const onSigningDetailsChanged = (signingDetails) => {
    const { signMethod, deliveryChannel, twoStepAuthenticationMethod } = signingDetails;
    const hasSignMethod = !isNull(signMethod) && !isUndefined(signMethod);
    const hasDeliveryChannel = !isNull(deliveryChannel) && !isUndefined(deliveryChannel);
    const hasTwoStepAuthenticationMethod = !isNull(twoStepAuthenticationMethod)
      && !isUndefined(twoStepAuthenticationMethod);

    setCurrentSigningDetailsValues({
      ...currentSigningDetailsValues,
      ...(hasSignMethod && { signMethod }),
      ...(hasDeliveryChannel && { deliveryChannel }),
      ...(hasTwoStepAuthenticationMethod && { twoStepAuthenticationMethod }),
    });
  };

  const getParticipantValues = () => {
    const commonFields = {
      fullname: participant?.fullname,
      email: participant?.email,
      phoneNumber: participant?.phoneNumber,
      personalIdentification: participant?.ssn,
      role: participant?.type,
      countryData: { countryCode, country },
    };

    if (isIndividual) {
      return ({
        ...commonFields,
        country: { value: participant?.country },
      });
    }

    return ({
      ...commonFields,
      jobTitle: participant?.title,
    });
  };

  const [initialValues] = useState(getParticipantValues());

  const uniqueEmailValidation = (
    { params },
  ) => {
    const values = getParticipantValues();

    if (isGuest || (initialValues?.email !== values?.email)) {
      return false;
    }

    return hasParticipantEmail({ params, agreementId: agreement.id });
  };

  const uniquePhoneValidation = (
    { params },
  ) => {
    const values = getParticipantValues();

    if (isGuest || initialValues?.phoneNumber !== values?.phoneNumber) {
      return false;
    }

    return hasParticipantPhone({ params, agreementId: agreement.id });
  };

  const getSigningDetailsValues = () => ({
    signMethod: currentSigningDetailsValues?.signMethod,
    deliveryChannel: currentSigningDetailsValues?.deliveryChannel,
    twoStepAuthenticationMethod: currentSigningDetailsValues?.twoStepAuthenticationMethod,
  });

  const onRoleChanged = (roleId) => {
    setCurrentParticipantValues({
      ...currentParticipantValues,
      role: roleId,
    });
  };

  const renderDownstreamInfo = () => {
    const canUpdateDetails = (
      checkAcl(participant?.acl, 'participant:update:fullname')
      && checkAcl(participant?.acl, 'participant:update:title')
      && checkAcl(participant?.acl, 'participant:update:phone_number')
    );

    if (participant && !canUpdateDetails) {
      return (
        <InfoBox
          bodyText={(
            <Message
              id="Updates to the user are automatically reflected here."
              comment="Info box to show when editing a colleague."
            />
          )}
        />
      );
    }

    return null;
  };

  const renderSigningDetails = () => {
    if (!isOwner || isFormerColleague(participant) || isImportInProgress(agreement)) {
      return null;
    }

    const getDescription = () => {
      if (isOwner && isColleague) {
        return (
          <>
            <p>
              <Message
                id="The sign method decides how this person will identify themselves when signing."
                comment="Tooltip for sign method dropdown"
              />
            </p>
          </>
        );
      }
      return (
        <>
          <p>
            <Message
              id="The sign method decides how the identity of your counterparty is verified."
              comment="Tooltip for sign method dropdown"
            />
          </p>
          <p>
            <Message
              id="More sign methods can be added by enabling extensions."
              comment="Tooltip for sign method dropdown"
            />
          </p>
        </>
      );
    };

    return (
      <SigningDetails
        onChange={onSigningDetailsChanged}
        hideSignMethod={currentParticipantValues.role !== TYPE_IS_SIGNATORY}
        colleague={isColleague}
        agreement={agreement}
        participant={participant}
        values={getSigningDetailsValues()}
        participantAcl={participant?.acl}
        signMethodsDescription={getDescription()}
      />
    );
  };

  const renderBody = () => (
    <>
      {renderDownstreamInfo()}
      <ParticipantSection
        individual={isIndividual}
        isColleague={isColleague}
        validateUniqueEmail={uniqueEmailValidation}
        validateUniquePhone={uniquePhoneValidation}
        onRoleChanged={onRoleChanged}
        values={getParticipantValues()}
        isPhoneNumberRequired={isPhoneNumberRequired(currentSigningDetailsValues)}
        isEmailRequired={
          isEmailRequired(currentSigningDetailsValues) && !isImportInProgress(agreement)
        }
        hiddenRoles={getHiddenRoles(agreement, party, participant)}
        participantAcl={participant?.acl}
        isOwner={isOwner}
        currentSignMethod={currentSigningDetailsValues?.signMethod}
        isAgreementPublished={agreement && isPublished(agreement)}
        agreement={agreement}
        setCountryCode={setCountryCode}
        setCountry={setCountry}
        participant={participant}
      />
      {renderSigningDetails()}
    </>
  );

  const getErrorActions = () => null;
  const getModalTitle = () => {
    if (isOwner && isColleague) {
      return (
        <Message
          id="Edit colleague"
          comment="Dialog title for the edit participant modal"
        />
      );
    }
    return (
      <Message
        id="Edit participant"
        comment="Dialog title for the edit participant modal"
      />
    );
  };

  const resetForm = () => {
    resetRpcState();
  };

  const checkForEmailUpdate = (data) => {
    const { agreements } = data.entities;
    const newAgreement = agreements[data.result];

    const updatedParticipant = getParticipantById(newAgreement, participant.id);

    if (updatedParticipant.email === participant.email) {
      return null;
    }

    return toast.success({
      id: 'counterparty-email-updated',
      title: <Message
        id="Email updated"
        comment="Title for the info message when the email of the counterparty has been updated."
      />,
      description: <Message
        id="The counterparty has been notified."
        comment="Description text for the info message when the email of the counterparty has been updated."
      />,
      duration: 5000,
    });
  };

  const updateAiImportData = () => {
    if (!agreement.availableOptions?.isAiImport) {
      return;
    }
    dispatch(agreementsReducer.fetchAiImportData({ id: agreement.id }));
  };

  const updateParticipant = (params) => dispatch(agreementsReducer.updateParticipant({
    id: participant.id,
    data: {
      ...params,
    },
    pipe: {
      onSuccess: (data) => {
        checkForEmailUpdate(data);
        updateAiImportData();
      },
    },
  }));

  const removeParticipant = (params) => dispatch(agreementsReducer.removeParticipant({
    id: participant.id,
    data: {
      ...params,
    },
  }));

  const enterConfirmationStateHandler = (state) => {
    setRemoveParticipantConfirmation(state);
  };

  const handleRemoveParticipant = () => {
    removeParticipant({
      agreement: agreement.id,
      token: guestToken,
    });
  };

  const canRemoveParticipant = () => {
    if (isColleague) {
      if (isOwner) {
        return checkAcl(agreement.acl, 'agreement:participant:colleague:remove');
      }

      return checkAcl(agreement.acl, 'agreement:participant:own_party:remove');
    }

    return checkAcl(agreement.acl, 'agreement:participant:other_party:remove');
  };

  const renderRemoveParticipantButton = () => {
    if (!canRemoveParticipant()) {
      return <div />;
    }

    if (removeParticipantConfirmation) {
      return (
        <RemoveParticipant
          isConfirmationState
          onEnterConfirmationState={() => enterConfirmationStateHandler(false)}
        />
      );
    }

    return (
      <RemoveParticipant
        onEnterConfirmationState={() => enterConfirmationStateHandler(true)}
      />
    );
  };

  const onRemoveClick = (closeCb: () => void) => {
    const onConfirmRemoveParticipant = () => {
      handleRemoveParticipant();
      closeCb();
    };

    const triggersApprovalsReset = hasAnyPendingApproverApproved(agreement);
    const triggersSignatureReset = willTriggerSignatureReset({ agreement, participant });
    const partiallySigned = isPartiallySigned(agreement);

    if (triggersSignatureReset) {
      if (triggersApprovalsReset) {
        if (partiallySigned) {
          // Document is partially signed and has approvals.
          handleApprovalsSignaturesResetOnEdit(onConfirmRemoveParticipant, 'participantRemoved');
        } else {
          // Document has approvals but is not partially signed.
          handleApprovalsResetOnEdit(onConfirmRemoveParticipant, 'participantRemoved');
        }
      } else if (partiallySigned) {
        // Document is partially signed but has no approvals.
        handleSignatureResetOnEdit(onConfirmRemoveParticipant, 'participantRemoved');
      } else {
        onConfirmRemoveParticipant();
      }
    } else {
      onConfirmRemoveParticipant();
    }
  };

  const renderConfirmButton = (formProps) => {
    const confirmChange = () => {
      setIsChangingRoleWarningModalOpen(false);
      confirmCallback();
    };

    return (
      <>
        <ChangingRoleWarningModal
          setIsChangingRoleWarningModalOpen={setIsChangingRoleWarningModalOpen}
          isChangingRoleWarningModalOpen={isChangingRoleWarningModalOpen}
          confirmChange={confirmChange}
        />
        <ConfirmButton
          onClick={
            (...args) => {
              formProps.handleSubmit({ ...args });
            }
          }
          isLoading={rpcState.loading}
          disabled={
            rpcState.loading
            || !isEmpty(formProps.errors)
            || formProps.pristine
          }
        />
      </>
    );
  };

  const actionButtonClassNames = clsx(style.ActionButtons, {
    [style.SmallerGap]: !removeParticipantConfirmation,
  });

  const getActions = ({ closeConfirmation, formProps }) => {
    if (removeParticipantConfirmation) {
      if (hasPendingStateApprovers(agreement)
        && shouldShowRemoveSignatoryWarning(agreement, participant)) {
        return (
          <div className={style.ConfirmationArea}>
            <div>
              <Message
                id="Are you sure you want to remove the participant?"
                comment="Warning to remove a participant in the participant modal"
              />
            </div>
            <div className={style.ConfirmationActionButtons}>
              <CancelButton
                onClick={() => setRemoveParticipantConfirmation(false)}
                modalKey="edit participant - error action"
              />
              <RemovingParticipantFromDocument
                setIsRemoveParticipantModalOpen={setIsRemoveParticipantModalOpen}
                isRemoveParticipantModalOpen={isRemoveParticipantModalOpen}
                agreement={agreement}
                participant={participant}
                guestToken={guestToken}
                handleSignatureResetOnEdit={handleSignatureResetOnEdit}
                setRemoveParticipantConfirmation={setRemoveParticipantConfirmation}
              >
                <Button
                  color="red"
                  icon={Delete}
                  onClick={() => setIsRemoveParticipantModalOpen(true)}
                >
                  <Message
                    id="Remove"
                    comment="Button label for removing a participant"
                  />
                </Button>
              </RemovingParticipantFromDocument>
            </div>
          </div>
        );
      }

      return (
        <div className={style.ConfirmationArea}>
          {renderRemoveParticipantButton()}
          <div className={style.ActionButtons}>
            <CancelButton
              onClick={() => setRemoveParticipantConfirmation(false)}
              modalKey="edit participant - error action"
            />
            <Button
              color="red"
              data-testid="delete"
              icon={Delete}
              onClick={() => onRemoveClick(closeConfirmation)}
            >
              <Message
                id="Remove"
                comment="Button label for removing a participant"
              />
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={style.ConfirmationArea}>
        {renderRemoveParticipantButton()}
        <div className={actionButtonClassNames}>
          <CancelButton onClick={closeConfirmation} />
          {renderConfirmButton(formProps)}
        </div>
      </div>
    );
  };

  const handleSubmit = (formData: AgreementParticipant) => {
    if (!formData || isEmpty(formData)) {
      return;
    }

    const shouldInvite = formData.subject && formData.body ? 1 : 0;
    const getSignMethod = () => {
      if (!isUndefined(formData.signMethod) && isNumber(formData.signMethod.value)) {
        return formData.signMethod.value;
      }

      return formData.signMethod;
    };

    // Make sure we don't save the country code as phone number
    const phoneNumber = formData.phoneNumber === `+${formData.countryData.countryCode}` ? '+' : formData.phoneNumber || undefined;
    const hasDraftApproval = hasDraftApprovalFlow(agreement);
    const flowId = hasDraftApproval ? agreement.draftApprovalFlow.id : null;
    const blockIndex = hasDraftApproval ? getNewInternalApproverBlockIndex(agreement) : 0;

    const pendingStateFlowId = isSignOrderEnabled ? agreement.pendingStateFlow?.id : null;
    const signOrderBlockIndex = isSignOrderEnabled && !isConcluded(agreement)
      ? getParticipantSignOrderBlockIndex(agreement, participant.id) : 0;

    const resetSignature = willTriggerSignatureReset({ formData, agreement, participant });
    const didUpdateEmail = formData.email !== participant.email;

    setShowSuccessMessage(!(resetSignature || didUpdateEmail));

    const onConfirmUpdateParticipant = () => {
      updateParticipant({
        agreement: agreement.id,
        fullname: formData.fullname,
        email: formData.email,
        jobTitle: formData.jobTitle || undefined,
        country: formData.country?.value || formData.country || undefined,
        phoneNumber,
        personalIdentification: formData.personalIdentification || undefined,
        type: formData.role?.value,
        signMethod: getSignMethod(),
        deliveryChannel: formData.deliveryChannel?.value,
        twoStepAuthenticationMethod: formData.twoStepAuthenticationMethod?.value,
        invite: shouldInvite,
        token: guestToken,
        flowId,
        blockIndex,
        pendingStateFlowId,
        signOrderBlockIndex,
      });
    };

    const newRole = isNumber(formData.role?.value) ? formData.role?.value : participant?.type;
    if (hasPendingStateApprovers(agreement)
      && shouldShowRoleEditWarningModal(agreement, participant, newRole)
    ) {
      setIsChangingRoleWarningModalOpen(true);
      setConfirmCallback(() => onConfirmUpdateParticipant);
      return;
    }

    const triggersApprovalsReset = hasAnyPendingApproverApproved(agreement);
    const triggersSignatureReset = willTriggerSignatureReset({ formData, agreement, participant });
    const partiallySigned = isPartiallySigned(agreement);

    if (triggersSignatureReset) {
      if (triggersApprovalsReset) {
        if (partiallySigned) {
          // Document is partially signed and has approvals.
          handleApprovalsSignaturesResetOnEdit(onConfirmUpdateParticipant, 'participantUpdated');
        } else {
          // Document has approvals but is not partially signed.
          handleApprovalsResetOnEdit(onConfirmUpdateParticipant, 'participantUpdated');
        }
      } else if (partiallySigned) {
        // Document is partially signed but has no approvals.
        handleSignatureResetOnEdit(onConfirmUpdateParticipant, 'participantUpdated');
      } else {
        onConfirmUpdateParticipant();
      }
    } else {
      onConfirmUpdateParticipant();
    }
  };

  const handleOpen = () => {
    setCurrentParticipantValues(defaultParticipantValues);
    setCurrentSigningDetailsValues(defaultSigningDetailsValues);
  };

  return (
    <ModalForm
      body={renderBody()}
      actions={getActions}
      errorActions={getErrorActions}
      title={getModalTitle()}
      onOpen={handleOpen}
      formState={rpcState}
      resetFormState={resetRpcState}
      onSubmit={handleSubmit}
      onClose={resetForm}
      modalKey="edit contract counterparty modal"
      isWideModal
      showSuccessMessage={showSuccessMessage}
    >
      {children}
    </ModalForm>
  );
};

export default localize<Props>(EditParticipantComponent);
