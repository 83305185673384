/* eslint-disable no-param-reassign */

import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  cloneDeep,
  every,
  find,
  get,
  has,
  head,
  isEmpty,
  set,
  uniqueId,
  omit,
  isEqual,
  slice,
  concat,
  toNumber,
  includes,
  isArray,
  findIndex,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import log from 'logging';

import { DEFAULT_ROUNDING_PRECISION } from 'utils/math';
import localStorage from 'utils/local-storage';

import { BOX_TYPES, TYPE_NOT_SET } from 'agreement/constants';
import { PRICE_ROUNDING_METHOD, DEFAULT_QUANTITY_PRECISION } from 'components/contract-boxes/product-table-box/constants';
import { setFocusedEditor, getAnnotationsMap } from 'reducers/editor';
import agreementsReducer from 'reducers/entities/agreements';
import client from 'oneflow-client';
import {
  saveComment,
  resolveComment,
  saveSuggestion,
} from 'oneflow-client/agreements';

import {
  wrapComment,
  unwrapComment,
  resetNodes,
  updateNodesWithoutApplying,
  insertSuggestion,
  unwrapAnnotations,
} from 'components/contract-text-editor/editor-plugins/annotation-plugin';
import { deserializeMarkup } from 'components/contract-text-editor/deserializer';
import { getId } from 'components/contract-boxes/generic-box-helpers';
import { generatePdfBoxData } from 'components/contract-boxes/pdf-box/pdf-box-helpers';
import { getHiddenSections } from 'components/contract-actions/set-all-hidden-sections-collapsed/set-all-hidden-sections-collapsed';
import { removeAiProperty } from 'utils/remove-ai-property';

import {
  ADD_ATTACHMENT_FILE,
  ON_UPLOAD_PDF_FILE,
  getContractMetaData,
  getAttachedFilesTotalSize,
  getAttachedFilesTotalCount,
  getCurrentContractData,
  getCurrentBoxes,
  getCurrentContractId,
  getCurrentContractMessages,
  ON_SAVE_COMMENT,
  ON_RESOLVE_COMMENT,
  ON_SAVE_SUGGESTION,
  getPristineContractData,
  setCurrentContractMessages,
  setDataFieldExternalKeyValueMap,
  UPDATE_DATA_FIELD_MAPPINGS,
  UPDATE_DURATION_BOX_ATTRIBUTE,
  updateBox,
  updateBoxDataItem,
  updateDataField,
  updatePristineContract,
  updateMetaData as updateMetaDataAction,
  updateBoxConfig,
  updateBoxesPristineMap,
  updateIsPristine,
  isContractPristine,
  UPDATE_BOX_ACTION,
  SILENT_UPDATE_BOX_ACTION,
  UPDATE_BOX_CONFIG_ACTION,
  UPDATE_BOX_VISIBILITY_ACTION,
  UPDATE_BOX_DATA_ITEM_ACTION,
  UPDATE_DATA_FIELD_ACTION,
  UPDATE_PRICE_COLUMNS_ACTION,
  INSERT_BOX,
  EJECT_BOX,
  REMOVE_BOX,
  addBox,
  getCurrentBoxOrder,
  removeBox,
  recalculateAttachedFilesSizeAndCount,
  updateDataFieldMappings as updateDataFieldMappingsAction,
  updateTotalFileCountAndSize,
  getDataFieldExternalKeyValueMap,
  setPriceColumnsGroups,
  getCollapsedBoxIds,
  updateBoxOrder,
  UPDATE_BOX_ORDER_ACTION,
  UPDATE_SUGGESTION_STATUS_WITHOUT_SAVING,
  DUPLICATE_BOX,
  UPDATE_COLLAPSED_BOX_IDS,
  getDataFieldExternalKeyMap,
  SILENT_UPDATE_PDF_BOX_ACTION,
  DISCARD_CHANGES,
  setResetKey,
  DISCARD_NEW_FILE_UPLOADS,
  FILES_UPDATE_AFTER_DISCARD,
  FILES_STATUS_BOX_CLOSE,
  discardNewFileUploads,
} from 'reducers/current-contract';
import {
  createDataFieldExternalKeyValueMap,
  producePriceColumns,
  filterOutInvisibleBoxes,
} from 'reducers/helpers/current-contract';
import { getGuestToken } from 'agreement/selectors';
import { setUpAgreementMetaData } from 'sagas/agreements';

const isTextAndImagePristine = (updatedBox, pristineBox) => {
  const updatedData = updatedBox?.content?.data;
  const pristineData = pristineBox?.content?.data;
  if (!isArray(updatedData) || !isArray(pristineData)) {
    return isEqual(updatedBox.content, pristineBox.content);
  }

  return updatedData.every((textDataItem, textDataIndex) => {
    const pristineTextDataItem = pristineData[textDataIndex];
    if (!pristineTextDataItem) {
      return false;
    }

    return isEqual(textDataItem.value, pristineTextDataItem.value);
  });
};

const checkBoxPristineState = (updatedBox, pristineBox) => {
  const isConfigPristine = isEqual(updatedBox.config, pristineBox.config);

  if (!isConfigPristine) {
    return false;
  }

  if (pristineBox.type === BOX_TYPES.BOX_TEXT_AND_IMAGE) {
    return isTextAndImagePristine(updatedBox, pristineBox);
  }

  return isEqual(updatedBox.content, pristineBox.content);
};

const getNewDataItem = ({
  _id,
  asset,
  status,
  ownerParticipantId,
}) => {
  const newDataItem = {
    key: 'file',
    value: {
      assetExtension: asset.assetExtension,
      assetId: asset.assetId,
      assetIdentifier: asset.assetIdentifier,
      name: asset.assetName,
      hasFile: 1,
      status,
      ownerParticipantId,
    },
    _id,
  };

  return newDataItem;
};

export function* getPriceRoundingMethod() {
  const currentContractData = yield select(getCurrentContractData);
  const contract = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: currentContractData.agreementId })
  ));

  return contract.config?.priceRoundingMethod || PRICE_ROUNDING_METHOD;
}

export const generateAttachmentBoxData = ({
  box,
  asset,
  status,
  ownerParticipantId,
}) => {
  const _id = Number(uniqueId());
  const newDataItem = getNewDataItem({
    _id,
    asset,
    status,
    ownerParticipantId,
  });

  const updatedBox = cloneDeep(box);
  const previousData = box.content.data || [];

  updatedBox.content = {
    ...updatedBox?.content,
    data: [
      ...previousData,
      newDataItem,
    ],
  };

  updatedBox.config = {
    ...updatedBox?.config,
    order: [
      ...box.config.order,
      { _id },
    ],
  };

  return {
    newDataItem,
    updatedBox,
  };
};

export const getCurrentBox = (contract, boxId) => (
  contract.boxes[boxId] || find(contract.boxes, ({ _id }) => _id === boxId)
);

export const calculateIsPristine = (state) => {
  const {
    boxesMap,
    boxOrder,
    dataFieldsMap,
  } = state.currentContract.pristineState;

  return boxOrder && every(dataFieldsMap, Boolean) && every(boxesMap, Boolean);
};

export function* updatePristineState({ boxId, boxIsPristine }) {
  const isPristineCurrently = yield select(isContractPristine);

  yield put(updateBoxesPristineMap({
    [boxId]: boxIsPristine,
  }));

  if (!boxIsPristine) {
    if (!isPristineCurrently) {
      return;
    }

    yield put(updateIsPristine(false));
    return;
  }

  const isPristine = yield select(calculateIsPristine);
  yield put(updateIsPristine(isPristine));
}

export function* addAttachmentFileToBox({
  boxId,
  asset,
  status,
  ownerParticipantId,
}) {
  const currentContractData = yield select(getCurrentContractData);

  if (!currentContractData) {
    return;
  }

  const box = getCurrentBox(currentContractData, boxId);

  if (!box) {
    return;
  }

  const { updatedBox } = generateAttachmentBoxData({
    box,
    asset,
    status,
    ownerParticipantId,
  });

  const priceRoundingMethod = yield call(getPriceRoundingMethod);

  yield put(updateBox(updatedBox, priceRoundingMethod));

  yield call(updatePristineState, {
    boxId,
    boxIsPristine: false,
  });
}

const defaultDurationValues = {
  duration: '12m',
  startDate: null,
  endDate: null,
  initialDuration: null,
  noticePeriod: '3m',
};

const parseNewDurationData = (box, durationData) => {
  const previousDurationData = box.content.agreement;

  if (has(durationData, 'type') && previousDurationData.type !== durationData.type) {
    return {
      type: durationData.type,
      ...defaultDurationValues,
    };
  }

  return {
    ...previousDurationData,
    ...durationData,
  };
};

function* updateAiImportData(agreementId, boxId) {
  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: agreementId })
  ));
  if (agreement.availableOptions?.isAiImport) {
    const updatedData = removeAiProperty('boxes', agreement.aiImportData, boxId);

    yield put(agreementsReducer.setAgreements({
      [agreementId]: {
        aiImportData: updatedData,
      },
    }));
  }
}

export function* updateDurationBoxContent({
  boxId,
  durationData,
}) {
  const currentContractData = yield select(getCurrentContractData);

  if (!currentContractData) {
    return;
  }

  const box = getCurrentBox(currentContractData, boxId);

  if (!box) {
    return;
  }

  const newDurationData = parseNewDurationData(box, durationData);

  const updatedBox = {
    ...box,
    content: {
      agreement: newDurationData,
    },
  };

  const priceRoundingMethod = yield call(getPriceRoundingMethod);
  const agreementId = yield select(getCurrentContractId);

  yield put(updateBox(updatedBox, priceRoundingMethod));

  const pristineContractData = yield select(getPristineContractData);
  const pristineBox = pristineContractData.boxes[boxId];

  if (!pristineBox) {
    return;
  }

  yield call(updatePristineState, {
    boxId,
    boxIsPristine: checkBoxPristineState(updatedBox, pristineBox),
  });

  yield call(updateAiImportData, agreementId, boxId);
}

export function* updateMetaData() {
  try {
    const currentContractData = yield select(getCurrentContractData);
    const dataFieldExternalKeyValueMap = yield select(getDataFieldExternalKeyValueMap);

    if (!currentContractData || isEmpty(dataFieldExternalKeyValueMap)) {
      return;
    }

    const { boxes } = currentContractData;
    const {
      boxesWithEmptyRequiredInput,
      priceColumnsGroups,
    } = yield select(getContractMetaData);

    const priceColumns = priceColumnsGroups
      .map((priceColumnsGroup) => priceColumnsGroup.priceColumns);

    const shouldCheckVisibility = priceColumns.length > 0 || boxesWithEmptyRequiredInput.length > 0;

    if (!shouldCheckVisibility) {
      return;
    }

    const hasVisibleUnfilledRequiredSections = filterOutInvisibleBoxes(
      boxesWithEmptyRequiredInput,
      dataFieldExternalKeyValueMap,
      boxes,
    );

    const metaData = {
      boxesWithEmptyRequiredInput: hasVisibleUnfilledRequiredSections,
    };

    yield put(updateMetaDataAction(metaData));
  } catch (error) {
    yield call(log.error, error);
  }
}

export function* updatePriceColumns() {
  try {
    const currentContractData = yield select(getCurrentContractData);
    if (!currentContractData) {
      return;
    }

    const priceColumns = producePriceColumns(
      currentContractData.boxes,
      currentContractData.boxOrder,
      currentContractData.dataFieldExternalKeyValueMap,
    );
    yield put(setPriceColumnsGroups(priceColumns));
  } catch (error) {
    yield call(log.error, error);
  }
}

export function* updateDataFieldMappings() {
  try {
    const currentContractData = yield select(getCurrentContractData);
    if (!currentContractData) {
      return;
    }

    const dataFieldExternalKeyValueMap = createDataFieldExternalKeyValueMap(
      currentContractData.data,
    );
    yield put(setDataFieldExternalKeyValueMap(dataFieldExternalKeyValueMap));

    yield call(updateMetaData);
  } catch (error) {
    yield call(log.error, error);
  }
}

export function* updateDataFieldAction(action) {
  const { dataField } = action;
  const pristineContractData = yield select(getPristineContractData);

  yield put(updateDataField(dataField, pristineContractData));
  yield call(updateDataFieldMappings);
  yield call(updatePriceColumns);

  const isPristine = yield select(calculateIsPristine);
  yield put(updateIsPristine(isPristine));
}

function* updateMessages(response) {
  const { id: agreementId, checksum } = response.agreement;
  const { allMessages } = yield select(getCurrentContractMessages);

  const updatedMessages = [
    ...allMessages,
    response,
  ];
  yield put(agreementsReducer.setAgreements({
    [agreementId]: {
      messages: updatedMessages,
      checksum,
    },
  }));

  yield put(setCurrentContractMessages(updatedMessages));
}

export function* onSaveAnnotationSuccess({
  boxId,
  editor,
  response,
  onSuccess,
}) {
  const currentContractData = yield select(getCurrentContractData);

  yield call(updateMessages, response);

  resetNodes(editor, { nodes: response.nodes });
  editor.history.undos = [];

  const { pristineContract } = currentContractData;
  const box = getCurrentBox(currentContractData, boxId);
  const data = get(box, 'content.data');
  const boxDataToUpdate = head(data);
  const updatedData = {
    value: {
      ...boxDataToUpdate.value,
      nodes: editor.children,
    },
  };

  const priceRoundingMethod = yield call(getPriceRoundingMethod);

  yield put(updateBoxDataItem(
    getId(box),
    getId(boxDataToUpdate),
    updatedData,
    priceRoundingMethod,
  ));

  const updatedPristineContract = cloneDeep(pristineContract);
  const targetBox = updatedPristineContract.boxes[boxId];

  if (targetBox._id) {
    delete targetBox._id;
  }

  const targetPristineDataToUpdate = head(get(targetBox, 'content.data'));
  set(targetPristineDataToUpdate, 'value.nodes', editor.children);

  yield put(updatePristineContract(updatedPristineContract));
  yield call(onSuccess, response.id);
}

export function* onSaveComment({
  contractId,
  boxId,
  dataId,
  editorSelection,
  editor,
  message,
  onSuccess,
  onFailure,
}) {
  const guestToken = yield select(getGuestToken);

  const tempAnnotationId = uniqueId();

  const wrappedNodes = updateNodesWithoutApplying(editor, () => {
    wrapComment(editor, editorSelection, { _id: tempAnnotationId });
  });

  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: contractId })
  ));

  try {
    const response = yield call(saveComment, {
      id: contractId,
      boxId,
      dataId,
      nodes: wrappedNodes,
      message,
      checksum: agreement.checksum,
      guestToken,
    });

    yield call(onSaveAnnotationSuccess, {
      boxId,
      editor,
      response,
      onSuccess,
    });
  } catch (error) {
    yield call(onFailure, error);
  }
}

export function* onResolveSuccess({
  annotationId,
  contractId,
  resolvedByParticipant,
}) {
  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: contractId })
  ));
  const agreementMessages = cloneDeep(agreement?.messages);
  const commentToResolve = agreementMessages.find((message) => message.id === annotationId);

  if (commentToResolve) {
    commentToResolve.config.status = 'resolved';
    commentToResolve.resolvedByParticipant = resolvedByParticipant;
    yield put(agreementsReducer.setAgreements({
      [contractId]: {
        messages: agreementMessages,
      },
    }));
  }

  yield put(setCurrentContractMessages(agreementMessages));
}

export function* onResolveSuggestion({
  suggestionId,
  contractId,
  myParticipant,
  resolveStatus,
  editor,
}) {
  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: contractId })
  ));
  const agreementMessages = cloneDeep(agreement?.messages);
  const suggestionToResolve = agreementMessages.find((message) => message.id === suggestionId);

  if (suggestionToResolve) {
    suggestionToResolve.status = resolveStatus;
    suggestionToResolve.resolvedByParticipant = myParticipant;

    yield put(agreementsReducer.setAgreements({
      [contractId]: {
        messages: agreementMessages,
      },
    }));
  }

  yield put(setCurrentContractMessages(agreementMessages));
  editor.history.undos = [];
}

export function* resolveCommentWithoutNodes({
  contractId,
  annotationId,
  guestToken,
  resolvedByParticipant,
  amplitudeScope,
  onFailure,
}) {
  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: contractId })
  ));

  try {
    yield call(resolveComment, {
      agreementId: contractId,
      commentId: annotationId,
      checksum: agreement.checksum,
      guestToken,
      amplitudeScope,
    });
    yield call(onResolveSuccess, { annotationId, contractId, resolvedByParticipant });
  } catch (error) {
    yield call(onFailure, error);
  }
}

export function* onResolveComment({
  annotationId,
  resolvedByParticipant,
  amplitudeScope,
  onFailure,
}) {
  const guestToken = yield select(getGuestToken);
  const contractId = yield select(getCurrentContractId);
  const annotationsMap = yield select(getAnnotationsMap);
  const annotationMap = annotationsMap[annotationId];

  function* resolveWithoutUpdatingNodes() {
    yield call(resolveCommentWithoutNodes, {
      contractId,
      annotationId,
      guestToken,
      resolvedByParticipant,
      amplitudeScope,
      onFailure,
    });
  }

  if (!annotationMap) {
    yield resolveWithoutUpdatingNodes();
    return;
  }

  const {
    boxId,
    editor,
  } = annotationMap;

  const currentContractData = yield select(getCurrentContractData);
  const box = getCurrentBox(currentContractData, boxId);

  if (!box) {
    yield resolveWithoutUpdatingNodes();
    return;
  }

  const data = get(box, 'content.data');
  const boxDataToUpdate = head(data);

  const unwrappedNodes = updateNodesWithoutApplying(editor, () => {
    unwrapComment(editor, annotationId);
  });

  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id: contractId })
  ));

  try {
    yield call(resolveComment, {
      agreementId: contractId,
      commentId: annotationId,
      nodes: unwrappedNodes,
      checksum: agreement.checksum,
      guestToken,
      amplitudeScope,
    });

    const updatedData = {
      value: {
        ...boxDataToUpdate.value,
        nodes: unwrappedNodes,
      },
    };

    const priceRoundingMethod = yield call(getPriceRoundingMethod);

    yield put(updateBoxDataItem(
      getId(box),
      getId(boxDataToUpdate),
      updatedData,
      priceRoundingMethod,
    ));

    const { pristineContract } = currentContractData;
    const updatedPristineContract = cloneDeep(pristineContract);
    const targetBox = updatedPristineContract.boxes[boxId];

    if (targetBox._id) {
      delete targetBox._id;
    }

    const targetPristineDataToUpdate = head(get(targetBox, 'content.data'));
    set(targetPristineDataToUpdate, 'value.nodes', unwrappedNodes);

    yield put(updatePristineContract(updatedPristineContract));
    yield call(onResolveSuccess, { annotationId, contractId, resolvedByParticipant });
    yield put(setFocusedEditor(null));

    unwrapComment(editor, annotationId);
    editor.history.undos = [];
  } catch (error) {
    yield call(onFailure, error);
  }
}

export function* onSaveSuggestion({
  boxId,
  dataId,
  editor,
  editorSelection,
  suggestedText,
  readOnly,
  onSuccess,
  onFailure,
}) {
  const id = yield select(getCurrentContractId);
  const guestToken = yield select(getGuestToken);

  const nodes = updateNodesWithoutApplying(editor, () => {
    insertSuggestion(editor, editorSelection, suggestedText, readOnly);
  });

  const agreement = yield select((state) => (
    agreementsReducer.getAgreementSelector(state, { id })
  ));

  try {
    const response = yield call(saveSuggestion, {
      id,
      boxId,
      dataId,
      nodes,
      checksum: agreement.checksum,
      guestToken,
    });

    yield call(onSaveAnnotationSuccess, {
      boxId,
      editor,
      response,
      onSuccess,
    });
  } catch (error) {
    yield call(onFailure, error);
  }
}

export function* updateBoxAction(action) {
  const { box } = action;
  const priceRoundingMethod = yield call(getPriceRoundingMethod);
  yield put(updateBox(box, priceRoundingMethod));

  if (box.type === BOX_TYPES.BOX_PRODUCT_TABLE) {
    yield call(updatePriceColumns);
  }

  if (!box.id) {
    return;
  }

  const pristineContractData = yield select(getPristineContractData);
  const pristineBox = pristineContractData.boxes[box.id] || {};

  yield call(updatePristineState, {
    boxId: box.id,
    boxIsPristine: checkBoxPristineState(box, pristineBox),
  });
}

export function* silentUpdateBoxAction(action) {
  // updates a box along with pristine box update, so doesn't trigger save button
  const { box } = action;
  const priceRoundingMethod = yield call(getPriceRoundingMethod);
  yield put(updateBox(box, priceRoundingMethod));

  if (box.type === BOX_TYPES.BOX_PRODUCT_TABLE) {
    yield call(updatePriceColumns);
  }

  if (!box.id) {
    return;
  }

  const pristineContractData = yield select(getPristineContractData);
  const updatedPristineContractData = {
    ...pristineContractData,
    boxes: {
      ...pristineContractData.boxes,
      [box.id]: box,
    },
  };

  yield put(updatePristineContract(updatedPristineContractData));
}

export function* silentUpdatePdfBoxAction(action) {
  // updates a box along with pristine box update, so doesn't trigger save button
  const {
    boxId, pages, status, assetId,
  } = action;

  const currentBox = yield select((state) => getCurrentBox(state.currentContract, boxId));

  const updatedBox = cloneDeep(currentBox);

  if (isEmpty(updatedBox.content.data)) {
    return;
  }

  const dataIndexToUpdate = findIndex(
    updatedBox.content.data,
    (dataItem) => dataItem.key === 'file' && dataItem.value.assetId === assetId,
  );

  set(updatedBox, `content.data[${dataIndexToUpdate}].value.pages`, pages);
  set(updatedBox, `content.data[${dataIndexToUpdate}].value.status`, status);
  set(updatedBox, `content.data[${dataIndexToUpdate}].value.assetId`, assetId);

  yield call(silentUpdateBoxAction, { box: updatedBox });
}

export function* updateBoxConfigAction(action) {
  const { boxId, boxConfig } = action;
  const priceRoundingMethod = yield call(getPriceRoundingMethod);
  yield put(updateBoxConfig(boxId, boxConfig, priceRoundingMethod));

  const currentContractData = yield select(getCurrentContractData);
  const box = getCurrentBox(currentContractData, boxId);
  const pristineContractData = yield select(getPristineContractData);
  const pristineBox = pristineContractData.boxes[boxId];

  if (!box || !pristineBox) {
    return;
  }

  yield call(updatePristineState, {
    boxId,
    boxIsPristine: checkBoxPristineState(box, pristineBox),
  });
}

export function* updateBoxVisibilityAction(action) {
  yield call(updateBoxConfigAction, action);
  yield call(updatePriceColumns);
}

export function* updateBoxDataItemAction(action) {
  const { boxId, dataId, dataItem } = action;
  const { valueDataFieldKey } = dataItem.value;
  const hasDataField = Boolean(valueDataFieldKey);
  const priceRoundingMethod = yield call(getPriceRoundingMethod);

  yield put(updateBoxDataItem(boxId, dataId, dataItem, priceRoundingMethod));

  if (hasDataField) {
    yield put(updateDataFieldMappingsAction());
  }

  const currentContractData = yield select(getCurrentContractData);
  const box = getCurrentBox(currentContractData, boxId);
  const pristineContractData = yield select(getPristineContractData);
  const pristineBox = pristineContractData.boxes[boxId];

  if (!pristineBox) {
    return;
  }

  const pristineDataItem = pristineBox.content.data?.find(({ id }) => id === dataId);

  if (!box || !pristineDataItem) {
    return;
  }

  yield call(updatePristineState, {
    boxId,
    boxIsPristine: checkBoxPristineState(box, pristineBox),
  });
}

export function* updateCollapsedBoxIdsInLocalStorage() {
  const boxes = yield select(getCurrentBoxes);
  const dataFieldExternalKeyMap = yield select(getDataFieldExternalKeyMap);
  const dataFieldExternalKeyValueMap = yield select(getDataFieldExternalKeyValueMap);

  if (isEmpty(getHiddenSections(
    boxes,
    dataFieldExternalKeyValueMap,
    dataFieldExternalKeyMap,
  ))) {
    return;
  }

  const agreementId = yield select(getCurrentContractId);
  const collapsedBoxIds = yield select(getCollapsedBoxIds);

  // eslint-disable-next-line import/no-named-as-default-member
  localStorage.setItem(`collapsedBoxIds-${agreementId}`, JSON.stringify(collapsedBoxIds));
}

export function* getPdfFileValues(file, box) {
  const attachedFilesTotalCount = yield select(getAttachedFilesTotalCount);
  const attachedFilesTotalSize = yield select(getAttachedFilesTotalSize);

  let fileValues = {
    totalFileCount: attachedFilesTotalCount + 1,
    totalFileSize: attachedFilesTotalSize + file.size,
  };

  const files = get(box, 'content.data', []).filter((dataItem) => !dataItem._removed);
  const isReplace = files.length > 0;

  if (isReplace) {
    const currentPDFSize = files[0]?.value?.size || 0;
    const newAttachedFilesTotalSize = attachedFilesTotalSize - currentPDFSize + file.size;
    fileValues = {
      totalFileCount: attachedFilesTotalCount,
      totalFileSize: newAttachedFilesTotalSize,
    };
  }

  return fileValues;
}

export function* onUploadPdfFile({
  boxId,
  file,
  fileType,
  myParticipant,
  onSuccess,
  onFailure,
}) {
  const currentContractData = yield select(getCurrentContractData);

  if (!currentContractData) {
    return;
  }

  const box = getCurrentBox(currentContractData, boxId);

  if (!box) {
    return;
  }

  const contractId = yield select(getCurrentContractId);

  try {
    const asset = yield call(client.uploadDocument, {
      file,
      type: fileType,
      contractId,
    });

    const { updatedBox } = generatePdfBoxData({
      box,
      asset,
      ownerParticipantId: myParticipant?.id,
    });

    const { totalFileCount, totalFileSize } = yield call(getPdfFileValues, file, box);
    yield put(updateTotalFileCountAndSize(totalFileCount, totalFileSize));
    yield call(updateBoxAction, { box: updatedBox });
    yield call(onSuccess, asset);
  } catch (error) {
    yield call(onFailure, error);
  }
}

export function* updateBoxOrderAction(action) {
  const { boxOrder } = action;
  yield put(updateBoxOrder(boxOrder));
  yield call(updatePriceColumns);
}

const defaultProductColumnLabels = {
  name: 'Product',
  description: 'Description',
  price_1: 'Price 1',
  price_2: 'Price 2',
  count: 'Quantity',
};

const getInitialBoxData = (box, documentId, productColumnLabels) => ({
  [BOX_TYPES.BOX_TEXT_AND_IMAGE]: box,
  [BOX_TYPES.BOX_VIDEO]: box,
  [BOX_TYPES.BOX_PRODUCT_TABLE]: {
    ...box,
    config: {
      ...box.config,
      order: [],
      // Deprecated
      // counterpart_edit: true,
      // colleague_edit: true,
      treatHiddenCountAsOne: true,
      counterpartEdit: true,
      colleagueEdit: true,
      hide_sum: false,
      header: {
        label: 'Untitled',
        enabled: false,
      },
      pricePrecision: DEFAULT_ROUNDING_PRECISION,
      sumPrecision: DEFAULT_ROUNDING_PRECISION,
      quantityPrecision: DEFAULT_QUANTITY_PRECISION,
      affixes: {
        prefix: '',
        postfix: '',
      },
      columns: [
        {
          enabled: true,
          key: 'name',
          label: productColumnLabels.name,
        },
        {
          enabled: true,
          key: 'description',
          label: productColumnLabels.description,
        },
        {
          enabled: true,
          key: 'price_1',
          label: productColumnLabels.price_1,
          uuid: uuidv4(),
          fixed_amount: 0,
        },
        {
          enabled: true,
          key: 'price_2',
          label: productColumnLabels.price_2,
          uuid: uuidv4(),
          fixed_amount: 0,
        },
        {
          enabled: true,
          key: 'count',
          label: productColumnLabels.count,
        },
      ],
    },
    store: {
      ...box.store,
      sums: {
        price_1: 0,
        price_2: 0,
      },
    },
  },
  [BOX_TYPES.BOX_FORM]: {
    ...box,
    config: {
      ...box.config,
      // Deprecated
      // counterpart_edit: true,
      // colleague_edit: true,
      counterpartEdit: true,
      colleagueEdit: true,
      colCount: 2,
      order: [ // row and column indices
        [null, null],
      ],
    },
  },
  [BOX_TYPES.BOX_DURATION]: {
    ...box,
    content: {
      agreement: {
        id: documentId,
        type: TYPE_NOT_SET,
        duration: '12m',
        initialDuration: null,
        noticePeriod: '3m',
        startDate: null,
        endDate: null,
      },
    },
  },
  [BOX_TYPES.BOX_PDF]: {
    ...box,
    config: {
      ...box.config,
      counterpartEdit: true,
      colleagueEdit: true,
    },
  },
  [BOX_TYPES.BOX_ATTACHMENTS]: {
    ...box,
    config: {
      ...box.config,
      // Deprecated
      // counterpart_edit: true,
      // colleague_edit: true,
      counterpartEdit: true,
      colleagueEdit: true,
      order: [],
      requiredForSignatures: false,
    },
  },
  [BOX_TYPES.BOX_PRODUCT_SUMMATION]: box,
});

// TODO: This action can be merged with addBox after the end of migration
export function* insertBox(action) {
  const { index, boxType } = action;
  const _id = toNumber(uniqueId());

  const { productColumnLabels = defaultProductColumnLabels } = action;

  const documentId = yield select(getCurrentContractId);

  const baseBox = {
    _id,
    type: boxType,
    // used in legacy action later removed by removeUnusedAttributes
    agreement: documentId,
    config: {
      id: uuidv4(),
    },
    store: {
      id: uuidv4(),
    },
    content: {
      data: [],
    },
  };

  const box = getInitialBoxData(
    baseBox,
    documentId,
    productColumnLabels,
  )[boxType] || baseBox;

  yield put(addBox(box));

  const boxOrder = yield select(getCurrentBoxOrder);

  const newBoxOrder = concat(
    slice(boxOrder, 0, index),
    { _id },
    slice(boxOrder, index),
  );

  yield call(updateBoxOrderAction, { boxOrder: newBoxOrder });
}

// TODO: This action can be merged with removeBox after the end of migration
export function* ejectBox(action) {
  const { boxId, boxType } = action;

  const currentContractData = yield select(getCurrentContractData);

  if (!currentContractData) {
    return;
  }

  const newBoxOrder = currentContractData.boxOrder.filter((box) => getId(box) !== boxId);

  yield put(removeBox(boxId));
  yield call(updateBoxOrderAction, { boxOrder: newBoxOrder });
  yield put(updateDataFieldMappingsAction());

  if (includes([BOX_TYPES.BOX_PDF, BOX_TYPES.BOX_ATTACHMENTS], boxType)) {
    yield put(recalculateAttachedFilesSizeAndCount());
  }
  if (boxType === BOX_TYPES.BOX_PRODUCT_TABLE) {
    yield call(updatePriceColumns);
  }
}

const cloneFieldOrder = (order, idMap) => (
  order.map((row) => {
    const newOrder = [...row].map((field) => {
      if (!field) {
        return null;
      }
      const newId = idMap.get(getId(field));
      return { _id: newId };
    });
    newOrder.id = getId(row);
    return newOrder;
  })
);

const cloneProductOrder = (order, idMap) => (
  order.map((idObject) => {
    const newId = idMap.get(getId(idObject));
    return { _id: newId };
  })
);

const cloneProductColumns = (columns) => (
  columns.map((column) => {
    if (column.key === 'price_1' || column.key === 'price_2') {
      return { ...column, uuid: uuidv4() };
    }
    return column;
  })
);

const duplicateConfig = (config, boxType, idMap) => {
  const newConfig = {
    ...config,
    id: uuidv4(),
  };

  if (boxType === BOX_TYPES.BOX_FORM) {
    const clonedOrder = cloneFieldOrder(newConfig.order, idMap);
    newConfig.order = clonedOrder;
  }

  if (boxType === BOX_TYPES.BOX_PRODUCT_TABLE) {
    const clonedOrder = cloneProductOrder(newConfig.order, idMap);
    newConfig.order = clonedOrder;

    const clonedColumns = cloneProductColumns(newConfig.columns);
    newConfig.columns = clonedColumns;
  }

  return newConfig;
};

export function* duplicateBox(action) {
  try {
    const { boxId } = action;
    const boxes = yield select(getCurrentBoxes);
    const box = boxes[boxId];
    const data = get(box, 'content.data');
    const legacyMarkup = head(data)?.value?.text;
    const boxOrder = yield select(getCurrentBoxOrder);
    const annotationsMap = yield select(getAnnotationsMap);

    if (!box) {
      return new Error('Box not found');
    }

    const index = boxOrder.findIndex(({ _id, id }) => (_id === boxId || id === boxId)) + 1;
    const _id = Number(uniqueId());

    const idMap = new Map();

    const duplicatedData = box.content.data?.map((dataItem) => {
      const newId = toNumber(uniqueId());
      const oldId = getId(dataItem);
      idMap.set(oldId, newId);

      const newDataItem = {
        ...dataItem,
        _id: newId,
        value: {
          ...dataItem.value,
          id: uuidv4(),
        },
      };

      // Remove annotations
      if (box.type === BOX_TYPES.BOX_TEXT_AND_IMAGE) {
        const annotationEntryForBox = Object.entries(annotationsMap)
          .find(([, { boxId: id }]) => id === boxId);
        const editor = annotationEntryForBox ? annotationEntryForBox[1].editor : undefined;

        if (legacyMarkup) {
          newDataItem.value.nodes = deserializeMarkup(legacyMarkup);
        }

        if (editor) {
          const unwrappedNodes = unwrapAnnotations(editor.children);
          newDataItem.value.nodes = unwrappedNodes;
        }
      }

      return omit(newDataItem, [
        'id',
        'createdTime',
        'created',
        'createdTs',
        'updatedTime',
        'updated',
        'updatedTs',
      ]);
    });
    const duplicatedConfig = duplicateConfig(box.config, box.type, idMap);
    const duplicatedStore = {
      ...box.store,
      id: uuidv4(),
    };

    const newBox = {
      _id,
      type: box.type,
      config: duplicatedConfig,
      store: duplicatedStore,
      content: {
        data: duplicatedData || [],
      },
    };

    yield put(addBox(newBox));

    const newBoxOrder = concat(
      slice(boxOrder, 0, index),
      { _id },
      slice(boxOrder, index),
    );

    yield call(updateBoxOrderAction, { boxOrder: newBoxOrder });
  } catch (error) {
    yield call(log.error, error);
  }
  return null;
}

export function* discardChanges(action) {
  yield call(setUpAgreementMetaData, action.agreement);

  yield put(setResetKey(uniqueId('reset_key_')));
  yield put(discardNewFileUploads());
}

export function* discardFileUploads() {
  const files = yield select((state) => state.currentContract.filesUploadStatus.files);
  const filesAfterDiscard = files.filter((file) => file.saved);

  yield put({
    type: FILES_UPDATE_AFTER_DISCARD,
    files: filesAfterDiscard,
  });

  if (filesAfterDiscard.length === 0) {
    yield put({
      type: FILES_STATUS_BOX_CLOSE,
    });
  }
}

export default function* currentContract() {
  yield takeEvery(INSERT_BOX, insertBox);
  yield takeEvery(DUPLICATE_BOX, duplicateBox);
  yield takeEvery(EJECT_BOX, ejectBox);
  yield takeEvery(REMOVE_BOX, updatePriceColumns);
  yield takeEvery(UPDATE_DURATION_BOX_ATTRIBUTE, updateDurationBoxContent);
  yield takeEvery(UPDATE_DATA_FIELD_MAPPINGS, updateDataFieldMappings);
  yield takeEvery(UPDATE_PRICE_COLUMNS_ACTION, updatePriceColumns);
  yield takeEvery(ON_SAVE_COMMENT, onSaveComment);
  yield takeEvery(ON_RESOLVE_COMMENT, onResolveComment);
  yield takeEvery(ON_SAVE_SUGGESTION, onSaveSuggestion);
  yield takeEvery(UPDATE_BOX_ACTION, updateBoxAction);
  yield takeEvery(SILENT_UPDATE_BOX_ACTION, silentUpdateBoxAction);
  yield takeEvery(SILENT_UPDATE_PDF_BOX_ACTION, silentUpdatePdfBoxAction);
  yield takeEvery(UPDATE_BOX_CONFIG_ACTION, updateBoxConfigAction);
  yield takeEvery(UPDATE_BOX_VISIBILITY_ACTION, updateBoxVisibilityAction);
  yield takeEvery(UPDATE_BOX_DATA_ITEM_ACTION, updateBoxDataItemAction);
  yield takeEvery(UPDATE_DATA_FIELD_ACTION, updateDataFieldAction);
  yield takeEvery(ADD_ATTACHMENT_FILE, addAttachmentFileToBox);
  yield takeEvery(ON_UPLOAD_PDF_FILE, onUploadPdfFile);
  yield takeEvery(UPDATE_BOX_ORDER_ACTION, updateBoxOrderAction);
  yield takeEvery(UPDATE_COLLAPSED_BOX_IDS, updateCollapsedBoxIdsInLocalStorage);
  yield takeEvery(UPDATE_SUGGESTION_STATUS_WITHOUT_SAVING, onResolveSuggestion);
  yield takeEvery(DISCARD_CHANGES, discardChanges);
  yield takeEvery(DISCARD_NEW_FILE_UPLOADS, discardFileUploads);
}
