// @flow

import * as React from 'react';
import get from 'lodash/get';
import { Message, localize } from '@oneflowab/pomes';

import type { MessageTranslator } from '@oneflowab/pomes';
import {
  DELIVERY_CHANNEL_EMAIL,
  DELIVERY_CHANNEL_SMS,
  DELIVERY_CHANNEL_EMAIL_AND_SMS,
  DELIVERY_CHANNEL_SAME_DEVICE,
  SIGN_METHOD_HANDWRITTEN_SIGN,
  MFA_CHANNEL_PERSONAL_IDENTIFICATION,
  MFA_CHANNEL_EMAIL,
  MFA_CHANNEL_SMS,
  MFA_CHANNEL_NONE,
} from 'agreement/participant/constants';
import {
  isViewer,
  isParticipantInOwnerParty,
} from 'agreement/participant';

import hasSigned from 'agreement/participant/has-signed';
import hasDeclined from 'agreement/participant/has-declined';
import isSignatory from 'agreement/participant/is-signatory';
import isOrganizer from 'agreement/participant/is-organizer';
import isInfluencer from 'agreement/participant/is-influencer';
import isInternalApprover from 'agreement/participant/is-internal-approver';
import isExternalApprover from 'agreement/participant/is-external-approver';
import hasSignMethodMarkedAsSigned from 'agreement/participant/has-sign-method-marked-as-signed';

import { checkAcl } from 'components/acl';
import { getSignMethod } from 'components/sign-methods/sign-methods';

import PersonalInfo from './personal-info';
import DeliveryInfo from './delivery-info';
import VisitCount from './visit-count';
import VisitInfo from './visit-info';
import DecisionInfo from './decision-info';
import HandwrittenSignature from './handwritten-signature';

import style from './participant-statistics.module.scss';

type Props = {
  contractPublishTime: string,
  agreement: Agreement,
  participant: AgreementParticipant,
  isOwnerParty: boolean,
  partyName: string,
  isContractDraft: boolean,
  isContractMarkedAsSigned: boolean,
  message: MessageTranslator,
  hideEventLog?: boolean,
  dateFormat: string,
};

type Channel = "email" | "sms" | "personal_identification" | null;

export const ParticipantStatisticsComponent = ({
  contractPublishTime,
  agreement,
  participant,
  isOwnerParty,
  partyName,
  isContractDraft,
  isContractMarkedAsSigned,
  message,
  hideEventLog,
  dateFormat,
}: Props) => {
  const {
    deliveryChannel,
    signMethod,
    mfaChannel,
    fullname,
    title,
    ssn,
    email,
    phoneNumber,
  } = participant;

  const hasDecisionInfo = isContractMarkedAsSigned || hasSigned(participant)
    || hasDeclined(participant);
  const isParticipantSignatory = isSignatory(participant);
  const participantInOwnerParty = isParticipantInOwnerParty(agreement.account, participant);

  const isMfaChannelType = (channel: Channel) => mfaChannel === channel;

  const getParticipantRole = () => {
    const roles = [
      {
        role: message({ id: 'Internal approver', comment: 'Participant role' }),
        condition: isInternalApprover,
      },
      {
        role: message({ id: 'Approver', comment: 'Participant role' }),
        condition: isExternalApprover,
      },
      {
        role: message({ id: 'Organizer', comment: 'Participant role' }),
        condition: isOrganizer,
      },
      {
        role: message({ id: 'Signatory', comment: 'Participant role' }),
        condition: isSignatory,
      },
      {
        role: message({ id: 'Influencer', comment: 'Participant role' }),
        condition: isInfluencer,
      },
      {
        role: message({ id: 'Viewer', comment: 'Participant role' }),
        condition: isViewer,
      },
    ];

    return get(
      roles.find((role) => role.condition(participant)),
      'role',
      null,
    );
  };

  const getDeliveryChannel = () => {
    const deliveryChannels = [
      {
        deliveryChannel: message({ id: 'Email', comment: 'Delivery channel' }),
        condition: DELIVERY_CHANNEL_EMAIL,
      },
      {
        deliveryChannel: message({ id: 'SMS', comment: 'Delivery channel' }),
        condition: DELIVERY_CHANNEL_SMS,
      },
      {
        deliveryChannel: message({ id: 'Email and SMS', comment: 'Delivery channel' }),
        condition: DELIVERY_CHANNEL_EMAIL_AND_SMS,
      },
      {
        deliveryChannel: message({ id: 'Same device', comment: 'Delivery channel' }),
        condition: DELIVERY_CHANNEL_SAME_DEVICE,
      },
    ];

    return get(
      deliveryChannels.find((channel) => channel.condition === deliveryChannel),
      'deliveryChannel',
      null,
    );
  };

  const getMfaChannel = () => {
    const mfaChannels = [
      {
        mfaChannel: message({ id: 'Email', comment: 'Multifactor authentication channel' }),
        condition: isMfaChannelType(MFA_CHANNEL_EMAIL),
      },
      {
        mfaChannel: message({ id: 'SMS', comment: 'Multifactor authentication channel' }),
        condition: isMfaChannelType(MFA_CHANNEL_SMS),
      },
      {
        mfaChannel: message({ id: 'Personal identification', comment: 'Multifactor authentication channel' }),
        condition: isMfaChannelType(MFA_CHANNEL_PERSONAL_IDENTIFICATION),
      },
      {
        mfaChannel: message({ id: 'None', comment: 'Multifactor authentication channel none' }),
        condition: isMfaChannelType(MFA_CHANNEL_NONE),
      },
    ];

    return get(
      mfaChannels.find((channel) => channel.condition),
      'mfaChannel',
      null,
    );
  };

  const currentSignMethod = getSignMethod(signMethod, message);

  const renderPermissionInfo = () => {
    const hasEditInternalApproverPermission = checkAcl(agreement.acl,
      'agreement:draft_approval_flow:update');

    if (isInternalApprover(participant) && !hasEditInternalApproverPermission) {
      return (
        <div className={style.RolePermissionInfo}>
          <Message
            id="Cannot be edited or removed"
            comment="Cannot be edited or removed label in participant card for Internal approver."
          />
        </div>
      );
    }

    return null;
  };

  const renderParticipantRole = () => (
    <div className={style.RoleLabel} data-testid="participant-role">
      {getParticipantRole()}
      {renderPermissionInfo()}
    </div>
  );

  const renderBasicInfo = () => (
    <div className={style.BasicInfo}>
      <div className={style.Header}>
        <span className={style.Name}>
          {fullname}
        </span>
      </div>
      <div className={style.PartyName}>
        {partyName}
      </div>
      <div className={style.Title}>
        {title}
      </div>
      {renderParticipantRole()}
    </div>
  );

  const renderHandwrittenSignature = () => {
    const {
      handwrittenSignature = null,
      handwrittenSignatureType = null,
    } = participant?.handwrittenSignatureData || {};
    if (hasSigned(participant) && participant.signMethod === SIGN_METHOD_HANDWRITTEN_SIGN) {
      return (
        <HandwrittenSignature
          handwrittenSignatureType={handwrittenSignatureType}
          handwrittenSignature={handwrittenSignature}
        />
      );
    }
    return null;
  };

  const renderEventLog = () => {
    if (hideEventLog || isContractDraft) {
      return null;
    }

    const eventsLog = [
      {
        event: (
          <VisitCount
            isOwnerParty={isOwnerParty}
            participant={participant}
            dateFormat={dateFormat}
            key={`visit-count-info-${agreement.id}`}
          />
        ),
        hide: isOwnerParty || !participant.visits,
      },
      {
        event: (
          <DeliveryInfo
            participant={participant}
            contractPublishTime={contractPublishTime}
            dateFormat={dateFormat}
            key={`delivery-info-${agreement.id}`}
          />
        ),
      },
      {
        event: (
          <VisitInfo
            isOwnerParty={isOwnerParty}
            participant={participant}
            dateFormat={dateFormat}
            key={`visit-info-${agreement.id}`}
          />
        ),
        hide: isContractDraft && !isOwnerParty,
      },
      {
        event: (
          <DecisionInfo
            agreement={agreement}
            participant={participant}
            isContractMarkedAsSigned={isContractMarkedAsSigned}
            dateFormat={dateFormat}
            key={`decision-info-${agreement.id}`}
          />
        ),
        hide: isContractMarkedAsSigned
          && !hasSignMethodMarkedAsSigned(participant) && !hasDecisionInfo,
      },
    ].filter((eventLog) => !eventLog.hide);

    return eventsLog.length === 0 ? null : (
      <div className={style.EventsLog}>
        <hr className={style.VisualLine} />
        {!participant.visits ? (
          <span className={style.NotViewed}>
            <Message
              id="Not viewed"
              comment="Visit information in participant visit card in the contract list"
            />
          </span>
        ) : eventsLog.map((eventLog) => eventLog.event)}
      </div>
    );
  };

  const renderPersonalInfo = () => {
    if (isOwnerParty) {
      if (isParticipantSignatory) {
        if (participantInOwnerParty) {
          return (
            <PersonalInfo
              ssn={ssn}
              email={email}
              phoneNumber={phoneNumber}
              signMethod={currentSignMethod?.label}
            />
          );
        }

        return (
          <PersonalInfo
            ssn={ssn}
            email={email}
            phoneNumber={phoneNumber}
            signMethod={currentSignMethod?.label}
            deliveryChannel={getDeliveryChannel()}
            twostepAuth={getMfaChannel() || agreement?.config?.defaultMfaChannel}
          />
        );
      }

      return (
        <PersonalInfo
          ssn={ssn}
          email={email}
          phoneNumber={phoneNumber}
        />
      );
    }

    if (isParticipantSignatory) {
      return (
        <PersonalInfo
          ssn={ssn}
          email={email}
          phoneNumber={phoneNumber}
          signMethod={currentSignMethod?.label}
          deliveryChannel={getDeliveryChannel()}
          twostepAuth={getMfaChannel() || agreement?.config?.defaultMfaChannel}
        />
      );
    }

    return (
      <PersonalInfo
        ssn={ssn}
        email={email}
        phoneNumber={phoneNumber}
        deliveryChannel={getDeliveryChannel()}
        twostepAuth={getMfaChannel() || agreement?.config?.defaultMfaChannel}
      />
    );
  };

  return (
    <div className={style.ParticipantStatistics}>
      {renderBasicInfo()}
      <hr className={style.VisualLine} />
      {renderPersonalInfo()}
      {renderEventLog()}
      {renderHandwrittenSignature()}
    </div>
  );
};

ParticipantStatisticsComponent.defaultProps = {
  hideEventLog: undefined,
};

export default localize<Props>(ParticipantStatisticsComponent);
