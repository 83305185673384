import { Message } from '@oneflowab/pomes';
import clsx from 'clsx';

import { ExtensionLogo } from 'components/extension/extension-logo';
import { ExtensionName } from 'components/extension/extension-name';
import { ExtensionOwner } from 'components/extension/extension-owner';
import DynamicLink from 'components/dynamic-link';

import rainbow from 'styles/rainbow-shadow.module.scss';

import style from './marketplace-card.module.scss';

type Trackable = string | {
  name: string,
  props: Record<string, string | number>,
  groups: Record<string, string | number>,
};

export type Props = {
  extension: Oneflow.Extension,
  trackable?: Trackable,
};

export const MarketplaceCard = ({
  extension,
  trackable,
}: Props) => {
  const renderLabel = () => {
    if (extension.state) {
      return (
        <span className={style.ActiveLabel}>
          <Message
            id="Active"
            comment="Label for active extensions"
          />
        </span>
      );
    }

    if (!extension.isEntitled) {
      return (
        <span className={style.EntitlementMissingLabel}>
          <Message
            id="Upgrade"
            comment="Label for extensions you are not entitled to"
          />
        </span>
      );
    }

    return null;
  };

  return (
    <div
      className={style.SectionContainer}
      id={extension.slug}
    >
      <DynamicLink
        className={clsx(style.LinkContainer, rainbow.ShadowOnHover)}
        trackable={trackable}
        url={`/marketplace/${extension.slug}`}
      >
        <div className={style.InnerContainer}>
          <div className={style.LogoContainer}>
            <div>
              <ExtensionLogo
                className={style.LogoSquare}
                extension={extension}
              />
            </div>
            <div style={{ flex: 1 }} />
            <div className={style.ActiveLabelContainer}>
              {renderLabel()}
            </div>
          </div>

          <div className={style.ContentContainer}>
            <div className={style.Title}>
              <ExtensionName extension={extension} />
            </div>
            <div className={style.SubTitle}>
              <ExtensionOwner extension={extension} />
            </div>
          </div>

          <div className={style.FooterContainer}>
            <span className={style.ExtensionTag}>CRM</span>
            <span className={style.ExtensionTag}>ATS</span>
            <span className={style.ExtensionTag}>Productivity</span>
          </div>
        </div>
      </DynamicLink>
    </div>
  );
};
