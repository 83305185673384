// @flow

import React from 'react';
import clsx from 'clsx';

import { getDateFormat } from 'date';
import { useSelector } from 'react-redux';
import { Message } from '@oneflowab/pomes';

import useDataValidator from 'data-validators/use-data-validator';

import Button from 'components/button';
import Cancel from 'components/icons/cancel';

import { RemoveConfirm } from 'components/modals/remove-confirm';
import CircularSpinner from 'components/icons/circular-spinner';
import ContractId from 'components/contract-id';
import { ContractName } from 'components/contract-name';
import ContractNameLink from 'components/contract-name/contract-name-link';
import { UserBadge } from 'components/user-badge';
import {
  isDraft,
  isPending,
  isOverdue,
  isAnySignedState,
  isMarkedAsSigned,
  isAnyDeclinedState,
} from 'agreement';
import {
  getAgreementCounterparties,
  getEnabledParticipants,
} from 'agreement/selectors';
import { getAccountFromSessionSelector } from 'reducers/session';
import { getCurrentWorkspaceSelector } from 'reducers/app';

import { sortByType } from 'agreement/participant';
import Tooltip from 'components/tooltip';

import ContractAlert from 'components/contract-card/contract-alert';
import MiniContractInsight from 'components/mini-contract-card/mini-contract-insight';
import Participants from 'components/contract-card/participants';
import ErrorBoundary from 'components/error-boundary';

import contractSchema from './data-validation/schema';
import conditionalValidations from './data-validation/conditional-validations';
import style from './mini-contract-card.module.scss';

type Props = {|
  // After typescript migration
  // TODO: agreement: contractSchema.infer<typeof contractSchema>
  agreement: Agreement,
  shouldRenderTitleAsLink?: boolean,
  onClick?: () => void,
  tabIndex?: string,
  hideInsight?: boolean,
  showParticipants?: boolean,
  canBeRemoved?: boolean,
  onRemove?: Function,
  isRemoving?: boolean,
  confirmState?: Function,
  resetRemoveState?: Function,
  cancelButton?: React.Node,
  clickableContractId?: boolean,
|};

const MiniContractCard = ({
  agreement,
  onClick,
  shouldRenderTitleAsLink,
  hideInsight,
  showParticipants,
  canBeRemoved,
  onRemove,
  resetRemoveState,
  confirmState,
  isRemoving,
  cancelButton,
  tabIndex = '0',
  clickableContractId,
}: Props) => {
  useDataValidator({
    schema: contractSchema,
    entity: agreement,
    conditionalValidations,
    // English since we are using this to log to rollbar
    validationMessage: 'Missing partial contract data in MiniContractCard',
  });

  const counterparties = getAgreementCounterparties(agreement);
  const participants = sortByType(getEnabledParticipants(agreement));
  const signedByParticipnt = isMarkedAsSigned(agreement);

  const account = useSelector((state) => getAccountFromSessionSelector(state));
  const workspace = agreement?.collection;

  const brandingDateFormat = workspace?.brandingDateFormat
    || account?.brandingDateFormat
    || agreement?.config?.dateFormat;
  const dateFormat = getDateFormat(brandingDateFormat);

  const contractCardClasses = clsx(style.MiniContractCard, {
    [style.NonClickable]: !onClick,
  });

  const contractInformationClasses = clsx(style.ContractInformation, {
    [style.Draft]: isDraft(agreement),
    [style.Pending]: isPending(agreement),
    [style.Overdue]: isOverdue(agreement),
    [style.Signed]: isAnySignedState(agreement),
    [style.Declined]: isAnyDeclinedState(agreement),
  });

  const renderContractName = () => {
    if (shouldRenderTitleAsLink) {
      return (
        <div className={style.ContractName}>
          <ContractNameLink
            agreement={agreement}
            target="_blank"
            className={style.NameLink}
            disableTooltip
          />
        </div>
      );
    }

    return (
      <ContractName agreement={agreement} />
    );
  };

  const getChildren = (onClickRemove: Function) => {
    let icon = Cancel;
    if (isRemoving) {
      icon = CircularSpinner;
    }

    if (canBeRemoved) {
      return (
        <Button
          icon={icon}
          onClick={onClickRemove}
          disabled={!canBeRemoved}
        />
      );
    }

    return (
      <Tooltip
        message={(
          <Message
            id="You don't have permissions to remove this document link."
            comment="Tooltip message in modal when attempting to remove a link without having access to remove that specific link from contract"
          />
        )}
        side="top"
      >
        <span>
          <Button
            icon={Cancel}
            disabled={!canBeRemoved}
          />
        </span>
      </Tooltip>
    );
  };

  const renderRemoveButton = () => {
    if (!onRemove) {
      return null;
    }

    return (
      <RemoveConfirm
        onConfirm={onRemove}
        confirmState={confirmState}
        resetConfirmState={resetRemoveState}
        confirmMessage={(
          <>
            <span className={style.Bold}>
              <Message
                id="Are you sure you want to remove this link?"
                comment="Warning message in modal when attempting to remove a link from contract"
              />
            </span>
            <Message
              id="Removing the link will not delete the linked contract."
              comment="Warning message in modal when attempting to remove a link from contract"
            />
          </>
        )}
        modalKey="remove link from contract modal"
      >
        {getChildren}
      </RemoveConfirm>
    );
  };

  const renderBadge = ({ fillColor }) => (
    <UserBadge
      diameter={32}
      borderSize={2}
      fillColor={fillColor}
      borderColor={style.gray}
    />
  );

  const renderCancelButtonInCard = () => {
    if (!cancelButton) {
      return null;
    }

    return cancelButton;
  };

  const renderParticipant = () => {
    if (!showParticipants) {
      return null;
    }

    return (
      <Participants
        agreement={agreement}
        counterparties={counterparties}
        participants={participants}
        isContractMarkedAsSigned={signedByParticipnt}
        bubbleClassName={style.ParticipantBubble}
        renderBadge={renderBadge}
        isViewCountHidden
        participantClassName={style.Participants}
      />
    );
  };

  return (
    <div
      className={contractCardClasses}
      onClick={onClick}
      role="button"
      onKeyDown={onClick}
      tabIndex={tabIndex}
    >
      <div className={contractInformationClasses}>
        <div className={style.NameRow}>
          <ContractAlert participants={participants} />
          {renderContractName()}
        </div>
        <div className={style.WorkspaceName}>
          <MiniContractInsight
            agreement={agreement}
            hideInsight={hideInsight}
            dateFormat={dateFormat}
            showWorkspaceName
          />
        </div>
      </div>
      <div className={style.RightContainer}>
        <ContractId id={agreement.id} clickableContractId={clickableContractId} />
      </div>
      {renderCancelButtonInCard()}
      {renderParticipant()}
      {renderRemoveButton()}
    </div>
  );
};

type PermissionBasedProps = {
  contractId: Number,
}

const WithoutPermission = (props: PermissionBasedProps) => {
  const currentWorkspace = useSelector((state) => getCurrentWorkspaceSelector(state));
  let workspaceName = currentWorkspace.name;
  const { contractId } = props;

  if (currentWorkspace.virtual) {
    workspaceName = (
      <Message
        id="Shared with me"
        comment="Displaying the name of the virtual workspace where contracts are shared with you"
      />
    );
  }

  const contractCardClasses = clsx(style.MiniContractCard, style.NonClickable);
  const contractInformationClasses = clsx(style.ContractInformation, style.Draft);

  return (
    <div
      className={contractCardClasses}
    >
      <div className={contractInformationClasses}>
        <div className={clsx(style.NameRow, style.NoContractViewPermission)}>
          <Message
            id="Contract not available"
            comment="Hint shown to the user when they don't have permission to the contract"
          />
        </div>
        <div className={style.WorkspaceName}>
          {workspaceName}
        </div>
      </div>
      <div className={style.RightContainer}>
        <ContractId id={contractId} />
      </div>
    </div>
  );
};

MiniContractCard.defaultProps = {
  shouldRenderTitleAsLink: undefined,
  onClick: undefined,
  hideInsight: undefined,
  canBeRemoved: undefined,
  onRemove: undefined,
  isRemoving: undefined,
  showParticipants: undefined,
  confirmState: undefined,
  resetRemoveState: undefined,
  cancelButton: undefined,
};

// No more HOCs please 🙏 ! keep it ungeneralised
const MiniContractCardWithErrorBoundary = (props: Props) => (
  <ErrorBoundary>
    <MiniContractCard {...props} />
  </ErrorBoundary>
);

MiniContractCardWithErrorBoundary.WithoutPermission = (props: Props) => (
  <ErrorBoundary>
    <WithoutPermission {...props} />
  </ErrorBoundary>
);

export default MiniContractCardWithErrorBoundary;
