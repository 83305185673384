// @flow

import * as React from 'react';

import ContractParties from 'components/contract-parties';

import style from './contract-name.module.scss';

type Props = {
  agreement: Agreement,
};

export const ContractName = ({ agreement }: Props) => {
  if (agreement.name) {
    return (
      <div className={style.ContractName}>
        {agreement.name}
      </div>
    );
  }

  return <ContractParties agreement={agreement} />;
};
