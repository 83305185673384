import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { isEnvironment } from 'utils/environment';

import Root from 'components/root';
import { amplitudeInit } from 'client-analytics/amplitude';

import './styles/base.scss';
import './styles/elements.scss';
import './styles/fonts.scss';
import './styles/variables.scss';
import { getAmplitudeTrackingUrl } from './helper';

if (!isEnvironment('development')) {
  import('polyfills');

  import('logging').then(({ rollbar }) => {
    rollbar.init();
  });
}

const serverUrl = getAmplitudeTrackingUrl();

amplitudeInit(window.TRACE_KEY, { serverUrl, defaultTracking: false });

const root = createRoot(document.getElementById('app'));
root.render(React.createElement(Root));
