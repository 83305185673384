// @flow

import * as React from 'react';
import { Message, type MessageTranslator } from '@oneflowab/pomes';

import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import type { Location } from 'react-router';

import adminPage from 'hocs/admin-page';

import GeneratedToken from 'components/extension/generated-token';

import {
  getSettingsComponent,
  getExtensionFromPath,
} from './marketplace-settings';
import { MarketplaceCard } from './marketplace-card/marketplace-card';

import style from './marketplace.module.scss';

export type Props = {
  extensions: Array<Extension>,
  queryExtensions: QueryFunc,
  toggleExtensionState: Extension => void,
};

export const getCategoryMapper = (message: MessageTranslator) => ({
  // "Catch-all" for now
  addons: message({ id: 'Add-ons', comment: 'Extension category' }),
  ai: message({ id: 'AI', comment: 'Extension category' }),
  // Deprecated
  integrations: message({ id: 'Integrations', comment: 'Extension category' }),
  sales_and_crm: message({ id: 'Sales and CRM', comment: 'Extension category' }),
  people_culture: message({ id: 'HR & Recruitment', comment: 'Extension category' }),
  productivity: message({ id: 'Productivity', comment: 'Extension category' }),
  automation: message({ id: 'Automation', comment: 'Extension category' }),
  support: message({ id: 'Support', comment: 'Extension category' }),
});

// Order matters
export const categoryMapper = {
  // "Catch-all" for now
  addons: (
    <Message
      id="Add-ons"
      comment="Extension category"
    />
  ),
  ai: (
    <Message
      id="AI"
      comment="Extension category"
    />
  ),
  // Deprecated
  integrations: (
    <Message
      id="Integrations"
      comment="Extension category"
    />
  ),
  sales_and_crm: (
    <Message
      id="Sales and CRM"
      comment="Extension category"
    />
  ),
  people_culture: (
    <Message
      id="HR & Recruitment"
      comment="Extension category"
    />
  ),
  productivity: (
    <Message
      id="Productivity"
      comment="Extension category"
    />
  ),
  automation: (
    <Message
      id="Automation"
      comment="Extension category"
    />
  ),
  support: (
    <Message
      id="Support"
      comment="Extension category"
    />
  ),
};

export class Extensions extends React.Component<Props> {
  componentDidMount() {
    const { queryExtensions } = this.props;

    queryExtensions();
  }

  renderCategories = () => {
    const { extensions } = this.props;
    const extensionsByCategory = extensions.reduce((acc, extension) => {
      const { category } = extension;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(extension);
      return acc;
    }, {});

    const sortedCategories = Object.keys(categoryMapper)
      .filter((category) => extensionsByCategory[category]);

    return (
      <>
        {sortedCategories.map((category) => (
          <React.Fragment key={category}>
            <div className={style.Header}>
              {categoryMapper[category]}
            </div>
            <div className={style.ExtensionsContainer}>
              {this.renderExtensions(extensionsByCategory[category])}
            </div>
          </React.Fragment>
        ))}
      </>
    );
  }

  renderExtensions = (extensions) => {
    const { toggleExtensionState } = this.props;

    const sortedExtensions = sortBy<Extension>(extensions, (extension: Extension) => [
      extension.name,
    ]);

    return sortedExtensions.map((extension) => (
      <React.Fragment key={extension.key}>
        <MarketplaceCard
          extension={extension}
          toggleExtensionState={toggleExtensionState}
          trackable={{
            name: 'Go To Marketplace Card',
            props: {
              location: 'marketplace',
              key: extension.key,
            },
          }}
        />
        <GeneratedToken
          extension={extension}
        />
      </React.Fragment>
    ));
  };

  render() {
    return (
      <div className={style.MainContainer}>
        {this.renderCategories()}
      </div>
    );
  }
}

type MapperProps = {
  location: Location,
  message: MessageTranslator,
  extensions: Array<Extension>,
  query: Query,
  toggleExtensionState: Extension => void,
  updateExtension: Extension => void,
  queryExtensions: () => void,
  getUpdateState: (extensionId: number) => UpdateState,
  resetUpdateState: (extensionId: number) => void,
  dateFormat: string,
};

const getModules = ({
  location,
  extensions,
  query,
  toggleExtensionState,
  updateExtension,
  getUpdateState,
  resetUpdateState,
  dateFormat,
}) => {
  if (!extensions) {
    return [];
  }
  const extension = getExtensionFromPath(extensions, location.pathname);
  const extensionId = get(extension, 'extensionId');

  return [{
    path: '/:extension',
    title: '',
    component: getSettingsComponent(extension, query.status === 'success'),
    extension,
    toggleExtensionState,
    updateExtension,
    updateState: getUpdateState(extensionId),
    resetUpdateState: () => resetUpdateState(extensionId),
    dateFormat,
  }];
};

export const propsMapper = ({ props }: { props: MapperProps }) => ({
  title: props.message({
    id: 'Marketplace',
    comment: 'Used as the page title of the marketplace page',
  }),
  modules: [getModules(props)],
  componentDidMount: () => {
    if (!props.location.pathname.endsWith('/marketplace')) {
      props.queryExtensions();
    }
  },
});

export default adminPage(propsMapper)(Extensions);
