import Svg from 'components/svg';

const De = Svg((
  <>
    <path d="M0 0h512v112H0z" fill="#000" />
    <path d="M0 112h512v112H0z" fill="#D00" />
    <path d="M0 224h512v112H0z" fill="#FFCE00" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

De.displayName = 'De';

export default De;
