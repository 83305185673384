import Svg from 'components/svg';

const Pl = Svg((
  <>
    <path d="M0 0h512v168H0z" fill="#F5F5F5" />
    <path d="M0 168h512v168H0z" fill="#D80027" />
  </>
), { viewBox: '0 0 512 336', fill: 'none' });

Pl.displayName = 'Pl';

export default Pl;
