type Item = { id: number };

export const removeAiProperty = (
  property: 'boxes' | 'parties' | 'participants' | 'closeTime',
  prevExtractedData: Oneflow.Agreement['aiImportData'],
  matchId?: number,
): Oneflow.Agreement['aiImportData'] => {
  if (!prevExtractedData) {
    return {
      boxes: [],
      parties: [],
      participants: [],
    };
  }

  if (!matchId) {
    return {
      ...prevExtractedData,
      [property]: '',
    };
  }

  if (matchId) {
    return {
      ...prevExtractedData,
      [property]: (prevExtractedData[property] as Item[]).filter((item) => item.id !== matchId),
    };
  }

  return prevExtractedData;
};
