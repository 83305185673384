import { useState, useEffect, useCallback } from 'react';
import { Message } from '@oneflowab/pomes';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import type { MessageTranslator } from '@oneflowab/pomes';
import type { match as Match } from 'react-router';
import type { Location } from 'history';

import adminPage from 'hocs/admin-page';
// eslint-disable-next-line import/named
import { hasAnyRole } from 'oneflow-client/roles';
import { isRoleEditable } from 'role';
import rolesReducer from 'reducers/entities/roles';

import * as EditableForm from 'components/editable-form';
import { checkAcl } from 'components/acl';
import { DetailsFooter } from 'components/details-footer';
import { LocalizedDateTime } from 'components/localized-date-time';
import TextField from 'components/text-field';
import RoleDetailsHeaderText from '../role-details-header-text';

import style from './details.module.scss';

type UpdateRole = {
  id: number,
  data: Oneflow.Role
};

type Props = {
  message: MessageTranslator,
  location: Location,
  match: Match,
};

export type FormData = {
  name: string,
}

const QUERY_NAME = 'admin/roles';

export const DetailsComponent = ({
  message,
  location,
  match,
}: Props) => {
  const dispatch = useDispatch();

  const id = parseInt(match.params.id, 10);
  const rolesQuery = useSelector(
    (state) => rolesReducer.getQuerySelector(state, { name: QUERY_NAME }),
  );
  const roles = useSelector(
    (state) => rolesReducer.getRolesSelector(state, { ids: rolesQuery.result }),
  );
  const role = roles.find((x) => x.id === id);
  const updateState = useSelector(
    (state) => rolesReducer.getUpdateSelector(state, { id }),
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const getInitialValues = () => ({ name: role?.name });

  const uniqueRoleName = (
    { params }: { params: any },
  ) => hasAnyRole({ params, entityId: role?.id });

  const checkEditPermission = useCallback(() => checkAcl(role?.acl, 'role:update:name'), [role?.acl]);

  const enableEditModeIfRedirected = useCallback(() => {
    if (!isEditMode && location?.query.edit && checkEditPermission()) {
      dispatch(replace(location.pathname));
      setIsEditMode(true);
    }
  }, [location?.query.edit, checkEditPermission, isEditMode, location.pathname, dispatch]);

  useEffect(() => {
    enableEditModeIfRedirected();
  }, [enableEditModeIfRedirected]);

  const renderSystemRoleWarning = () => (
    <div className={style.DisabledInformTextContainer}>
      <p className={style.DisabledInformText}>
        <Message
          id="This is a predefined system role and cannot be edited."
          comment="Header text for the role details page"
        />
      </p>
    </div>
  );

  const renderActionsHeader = () => (
    <RoleDetailsHeaderText role={role} />
  );

  if (!role || !role.acl) {
    return null;
  }

  const infoPairs = [
    {
      label: <Message
        id="Role ID"
        comment="ID label for the details footer."
      />,
      value: role.id,
    },
    {
      label: <Message
        id="Creation date"
        comment="Creation date label for the details footer"
      />,
      value: <LocalizedDateTime datetime={role?.createdTime} />,
    },
  ];

  return (
    <>
      <Form
        initialValues={getInitialValues()}
        onSubmit={(formData: UpdateRole) => {
          dispatch(rolesReducer.updateRole({
            id,
            data: formData,
            pipe: {
              onSuccess: () => {
                setIsEditMode(false);
              },
            },
          }));
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <EditableForm.Header>
              {isRoleEditable(role) ? (
                <EditableForm.Actions
                  resetFormState={() => {
                    dispatch(rolesReducer.updateRoleReset({
                      id,
                    }));
                  }}
                  formState={updateState}
                  isEditMode={isEditMode}
                  setIsEditMode={setIsEditMode}
                  disabled={!checkEditPermission()}
                />
              ) : renderSystemRoleWarning()}
              {renderActionsHeader()}
            </EditableForm.Header>
            <EditableForm.Body>
              <h2 className={style.Header}>
                <Message
                  id="Role details"
                  comment="Used as the title of the Role Details section on the role details page"
                />
              </h2>
              <div className={style.Row}>
                <EditableForm.Label htmlFor="name">
                  <Message
                    id="Name"
                    comment="Used as a label for the role details page."
                  />
                  <EditableForm.RequiredAsterisk isEditMode={isEditMode} />
                </EditableForm.Label>
                <Field
                  id="name"
                  name="name"
                  component={TextField}
                  value={role.name}
                  canEdit={checkAcl(role.acl, 'role:update:name')}
                  maxLength={50}
                  required
                  autoFocus
                  unique={{
                    text: message({
                      id: 'A role with this name already exists in the role list. Try another one.',
                      comment: 'Unqiueness error message on the role details page',
                    }),
                    param: 'name',
                    request: uniqueRoleName,
                    initialValue: role.name,
                  }}
                  disabled={!isEditMode}
                />
              </div>
            </EditableForm.Body>
          </form>
        )}
      />
      <DetailsFooter infoPairs={infoPairs} />
    </>
  );
};

type MapperProps = {
  message: MessageTranslator,
};

export default adminPage(({ props: { message } }: { props: MapperProps }) => ({
  title: message({ id: 'Details', comment: 'Page title for role details page.' }),
  modules: [[]],
}))(DetailsComponent);
