// @flow

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import { localize, Message } from '@oneflowab/pomes';

import agreementsReducer from 'reducers/entities/agreements';
import { getAccountFromSessionSelector } from 'reducers/session';

import { isTemplate } from 'agreement';
import { getOwnerPartyId } from 'agreement/agreement';
import { hasAnyPendingApproverApproved } from 'agreement/pending-state-flow';

import { getGuestToken, isAgreementOwner, isPartiallySigned } from 'agreement/selectors';

import ModalForm from 'hocs/modal-form';

import { checkAcl } from 'components/acl';
import Button from 'components/button';
import PartyCompany from 'components/party-company';

import RemoveParty from 'components/remove-party';
import { CancelButton } from 'components/buttons/cancel';
import { ConfirmButton } from 'components/buttons/confirm';
import Delete from 'components/icons/delete';

import style from './edit-company.module.scss';

type Props = {
  agreement: Agreement,
  children: React.Node,
  party?: AgreementParty,
  handleApprovalsResetOnEdit: (Function, string) => void,
  handleSignatureResetOnEdit: (Function, string) => void,
  handleApprovalsSignaturesResetOnEdit: (Function, string) => void,
};

export const EditCompanyComponent = ({
  agreement,
  children,
  party,
  handleApprovalsResetOnEdit,
  handleSignatureResetOnEdit,
  handleApprovalsSignaturesResetOnEdit,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [
    removePartyConfirmation,
    setRemovePartyConfirmation,
  ] = useState(false);

  const accountFromSession = useSelector((state) => (
    getAccountFromSessionSelector(state)
  ));

  const rpcState = useSelector((state) => (
    agreementsReducer.getUpdatePartySelector(state, { id: party.id })
  ));

  const guestToken = useSelector((state) => getGuestToken(state));

  const dispatch = useDispatch();

  const resetRpcState = () => dispatch(agreementsReducer.updatePartyReset({
    id: party.id,
  }));

  const getCompanyValues = () => ({
    companyName: party?.name,
    companyRegNumber: party?.orgnr,
    country: { value: party?.country },
  });

  const renderBody = () => (
    <>
      <PartyCompany
        values={getCompanyValues()}
        partyAcl={party?.acl}
      />
    </>
  );

  const getErrorActions = () => null;
  const getModalTitle = () => (
    <Message
      id="Edit company"
      comment="Dialog title for the edit company modal"
    />
  );

  const resetForm = () => {
    setIsOpen(false);
    resetRpcState();
  };

  const updateAiImportData = () => {
    if (agreement.availableOptions?.isAiImport) {
      dispatch(agreementsReducer.fetchAiImportData({
        id: agreement.id,
      }));
    }
  };

  const updateCompany = (params) => dispatch(
    agreementsReducer.updateParty({
      id: party.id,
      data: {
        ...params,
      },
      pipe: {
        onSuccess: updateAiImportData,
      },
    }),
  );

  const removeParty = (params) => dispatch(
    agreementsReducer.removeParty({
      id: party.id,
      data: { ...params },
    }),
  );

  const enterConfirmationStateHandler = (state) => {
    setRemovePartyConfirmation(state);
  };

  const handleRemoveParty = () => {
    removeParty({
      agreement: agreement.id,
      token: guestToken,
    });
  };

  const canRemoveParty = () => {
    const ownerPartyId = getOwnerPartyId(agreement);
    const isOwner = isAgreementOwner(accountFromSession, agreement);
    if (!isTemplate(agreement) && isOwner && party?.id === ownerPartyId) {
      return false;
    }

    if (party?.self === 1) {
      return checkAcl(agreement.acl, 'agreement:participant:own_party:remove');
    }

    return checkAcl(agreement.acl, 'agreement:participant:other_party:remove');
  };

  const renderRemovePartyButton = () => {
    if (!canRemoveParty()) {
      return <div />;
    }

    if (removePartyConfirmation) {
      return (
        <RemoveParty
          isConfirmationState
          onEnterConfirmationState={() => enterConfirmationStateHandler(false)}
        />
      );
    }

    return (
      <RemoveParty
        onEnterConfirmationState={() => enterConfirmationStateHandler(true)}
      />
    );
  };

  const onRemoveClick = (closeCb: () => void) => {
    const onConfirmRemoveParty = () => {
      handleRemoveParty();
      closeCb();
    };

    if (hasAnyPendingApproverApproved(agreement)) {
      if (isPartiallySigned(agreement)) {
        handleApprovalsSignaturesResetOnEdit(onConfirmRemoveParty, 'partyRemoved');
      } else {
        handleApprovalsResetOnEdit(onConfirmRemoveParty, 'partyRemoved');
      }
    } else if (isPartiallySigned(agreement)) {
      handleSignatureResetOnEdit(onConfirmRemoveParty, 'partyRemoved');
    } else {
      onConfirmRemoveParty();
    }
  };

  const getActions = ({ closeConfirmation, formProps }) => {
    if (removePartyConfirmation) {
      return (
        <div className={style.ConfirmationArea}>
          {renderRemovePartyButton()}
          <div className={style.ActionButtons}>
            <CancelButton
              onClick={() => setRemovePartyConfirmation(false)}
              className={style.CancelButtonDelete}
              modalKey="edit party - error action"
            />
            <Button
              color="red"
              data-testid="delete"
              icon={Delete}
              onClick={() => onRemoveClick(closeConfirmation)}
            >
              <Message
                id="Remove"
                comment="Button label used to delete a party in a modal"
              />
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={style.ConfirmationArea}>
        {renderRemovePartyButton()}
        <div className={style.ActionButtons}>
          <CancelButton onClick={closeConfirmation} />
          <ConfirmButton
            onClick={(...args) => formProps.handleSubmit(...args)}
            isLoading={rpcState.loading}
            disabled={
              rpcState.loading
              || !isEmpty(formProps.errors)
              || formProps.pristine
            }
          />
        </div>
      </div>
    );
  };

  const handleSubmit = (formData: AgreementParticipant) => {
    if (!formData || isEmpty(formData)) {
      return;
    }

    const onConfirmUpdateCompany = () => {
      updateCompany({
        agreement: agreement.id,
        companyName: formData.companyName,
        country: formData.country?.value || formData.country || undefined,
        companyRegNumber: formData.companyRegNumber || null,
        token: guestToken,
      });
    };

    if (hasAnyPendingApproverApproved(agreement)) {
      if (isPartiallySigned(agreement)) {
        handleApprovalsSignaturesResetOnEdit(onConfirmUpdateCompany, 'partyUpdated');
      } else {
        handleApprovalsResetOnEdit(onConfirmUpdateCompany, 'partyUpdated');
      }
    } else if (isPartiallySigned(agreement)) {
      handleSignatureResetOnEdit(onConfirmUpdateCompany, 'partyUpdated');
    } else {
      onConfirmUpdateCompany();
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <ModalForm
      body={renderBody()}
      actions={getActions}
      errorActions={getErrorActions}
      title={getModalTitle()}
      isOpen={isOpen}
      onOpen={handleOpen}
      formState={rpcState}
      resetFormState={resetRpcState}
      onSubmit={handleSubmit}
      onClose={resetForm}
      modalKey="edit contract party modal"
      isWideModal
    >
      {children}
    </ModalForm>
  );
};

export default localize<Props>(EditCompanyComponent);
